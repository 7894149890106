import { Component, OnInit, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { Helper } from 'src/app/shared/classes/helpers.class';
import { Utility } from 'src/app/async/utility';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService} from 'ngx-ui-loader';
@Component({
  selector: 'app-new-group-master',
  templateUrl: './new-group-master.component.html',
  styleUrls: ['./new-group-master.component.css']
})
export class NewGroupMasterComponent implements OnInit {

  productList$: Observable<any> = of([]);
  groupList$: Observable<any> = of([])
  groupList = [];
  selectedGroupList = [];
  isEdit = false;
  helper: Helper = new Helper();
  profileForm: FormGroup;
  selectedProductList = [];
  requestGroupList = [];
  groupName = "";
  groupNameValue = "";
  title = "Add New Client Group";
  groupId;
  clientMapId = []
  groupListBackup = [];
  selectedGroupListBackup = [];
  selectedDomainArray = [];
  //Domain Url
  domainUrl = "";
  isDomainValid = false;

  submited = false;
  // Validation Message
  isGroupEmpty = false;
  isProductEmpty = false;
  isGroupListEmpty = false;
  isView = false;
  isWhitespace = false;
  constructor(
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sharedApi: SharedService,
    public shareUtility: Utility,
    private dialogRef: MatDialogRef<NewGroupMasterComponent>,
    public loader: NgxUiLoaderService) {
    this.loader.start();
  }

  //@Comapny Id :
  //@companyFilter : Filter added.
  companyFilter(value) {
   //
    if (value && value.length > 0) {
      if (this.groupList.length > 0) {
        let str = value.toLowerCase();
        this.groupList = this.groupList.filter((obj) => obj['clientName'].toLowerCase().indexOf(str) > -1);
        this.groupList$ = of(this.groupList);
      }
    } else {
      this.groupList = this.groupListBackup;
      this.groupList$ = of(this.groupList);
    }
  }

  selectedCompanyFilter(value) {
    if (value && value.length > 0) {
      //if (this.groupList.length > 0) {
      if (this.groupListBackup.length > 0) {   //Work item : 1817
        let str = value.toLowerCase();
        this.selectedGroupList = this.selectedGroupListBackup.filter((obj) => obj['clientName'].toLowerCase().indexOf(str) > -1);
      }
    } else {
      this.selectedGroupList = this.selectedGroupListBackup;
    }
  }
  ngOnInit() {
    if (this.data['status'] == "edit_group") {
      this.isEdit = true;
      this.isView = false;
      this.title = "Edit Client Group";
      this.groupId = this.data['value']['groupId'];
      this.groupNameValue = this.data['value']['groupName'];
      this.groupName = this.data['value']['groupName'];
      // Group List
      //this.getGroupById(this.groupId);
      this.getProducts(this.groupId);
      // >>
      // Get Groups
      // Get Group As Per Tha Based.
      this.getGroups();
      this.getDomain();
    } else if (this.data['status'] == "view_group")  {
      this.isEdit = true;
      this.isView = true;
      this.title = "View Client Group....";
      this.groupId = this.data['value']['groupId'];
      this.groupNameValue = this.data['value']['groupName'];
      this.groupName = this.data['value']['groupName'];
      // Group List
      //this.getGroupById(this.groupId);
      this.getProducts(this.groupId);
      // >>
      // Get Groups
      // Get Group As Per Tha Based.
      this.getGroups();
      this.getDomain();
    } else {
      this.isView = false;
      this.isEdit = false;
      this.getProducts();
      // >>
      // Get Groups
      this.getGroups();
    }
    //>> getProducts
    //

  }

  // >> Remove Domain Index...
  // >> Selected Domain Array Remove Index..
  removeDomain(index) {
    this.selectedDomainArray.splice(index, 1);
  }


  domainKeyUp(event) {
    // Url Check
    // Regular Expression ...
    let isUrlValid = (userInput) => {
      var regexQuery = "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,100}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
      var url = new RegExp(regexQuery, "i");
      var regexQuery2 = "^\\..[a-zA-Z0-9]{1,}";
      var url2 = new RegExp(regexQuery2, "i");
      return url.test(userInput) || url2.test(userInput);
    }
    // Feature..
    // key paier value..
    let keyUPValue = event['target']['value'];
    // Check Url Valid...
    if (isUrlValid(keyUPValue)) {
      // Domain true ...
      this.isDomainValid = true;
      this.domainUrl = keyUPValue;
    } else {
      // is Domain Valid
      this.isDomainValid = false;
    }
  }
  // >> On Click Add Button On Domain Add
  // >> Added Domain New
  domainAdd() {
    this.domainEmpty = false;
    if (this.selectedDomainArray.length > 0) {
      let findObj = this.selectedDomainArray.find((obj) => obj == this.domainUrl);
      if (!findObj) {

        this.selectedDomainArray.push(this.domainUrl);
      }
    } else {
      this.selectedDomainArray.push(this.domainUrl);
    }
    document.getElementById("domain_input")["value"] = "";
    this.isDomainValid = false;
  }

  //Get Profile By Id
  // Get All Profile Response based on Profile Id
  getProducts(ProfileId = null) {
    let reqObj = {};
    if (ProfileId) {
      // Profile Id get automatic
      reqObj['ProfileId'] = null;
      reqObj['LoggedInUser'] = localStorage.getItem('userKey');
      reqObj['GroupId'] = this.groupId || null;
    } else {
      reqObj['ProfileId'] = null;
      reqObj['LoggedInUser'] = localStorage.getItem('userKey');
      reqObj['GroupId'] = this.groupId || null;
    }
    this.loader.start();
    //
    this.sharedApi.GetProductList(reqObj).subscribe(resp => {
      if (resp && resp['status'] == "Success") {
        this.productList$ = of(resp["value"]);
        //this.loader.stop();
        // If Edit Then
        if (this.isEdit) {
          this.setUpdateProductIds(resp["value"])
        }
      }
    }, error => {
      // 
        this.loader.stop();
    })
  }

  setUpdateProductIds(array) {
    if (array.length > 0) {
      // Map Row
      array.map((row) => {
        if (row['productHighlight']) {
          //Row Select Row if True Then
          this.selectedProductList.push(row['productId'])
        }
      })
    }

  }

  // >> selectProductList
  selectGroupList() {
    let res = [];
    this.groupList.map(row => {
      if (row['higlight']) {
        this.selectedGroupList.push({ ...row, isChecked: true });
        this.selectedGroupListBackup.push({ ...row, isChecked: true });
      }
      res.push({ ...row, isChecked: row['higlight'] });
    })

    // setTimeout...
    setTimeout(() => {
    //  this.selectedGroupList = res;
      this.groupList$ = of(res);
      this.groupList = res;
      //show No record msg on company list
     // this.companyListEmpty = (res.length > 0) ? false : true;


    }, 300)
  }

  //Get Group List By Id
  // Get All Group Response based on
  getGroups() {
    let reqObj = {
      LoggedInUser: localStorage.getItem('userKey'),
      GroupKey: this.groupId
    };
    this.sharedApi.GetAllParentCompany(reqObj).subscribe(resp => {
      if (resp && resp['status'] == "Success") {
        this.loader.stop();
        this.groupList$ = of(resp["value"]);
        this.groupList = resp["value"];

        //show No record msg on company list
        //this.companyListEmpty = (resp['value'].length > 0) ? false : true;


        this.groupListBackup = resp["value"];
        // Edit ..
        if (this.isEdit) {
          this.selectGroupList();
        }
      } else {
        this.loader.stop();
        //this.companyListEmpty = true;  //show No record msg on company list
      }

      if (this.groupList.length > 0) { }

    }, error => {
      this.loader.stop();
      //
    })
  }

  //to show No record message on company list
  companyListEmpty = false;


  //Get Group Domain By Id
  getDomain() {
    let reqObj = {
      LoggedInUser: localStorage.getItem('userKey'),
      GroupKey: this.groupId
    };
    this.sharedApi.GetDomainByGroupId(reqObj).subscribe(resp => {
      if (resp && resp['status'] == "Success") {
        //this.loader.stop();
        if (resp["value"] != null) {
          this.selectedDomainArray = resp["value"];
        }
      }
    }, error => {
      this.loader.stop();
      //
    })
  }

  // Group Change
  // On Change Group
  onChangeGroup(e, groupRow, index) {
    if (e['checked']) {
      if (this.selectedGroupList.length > 0) {
        let findObj = this.selectedGroupList.find((row) => row['clientId'] == groupRow['clientId']);
        this.isGroupListEmpty = false;
        if (!findObj) {
          this.selectedGroupList.push({ ...groupRow, isChecked: true });
          this.selectedGroupListBackup = [...this.selectedGroupList];
          this.groupList[index]['isChecked'] = true;
        }
      } else {
        this.isGroupListEmpty = false;
        this.groupList[index]['isChecked'] = true;
        this.selectedGroupList.push({ ...groupRow, isChecked: true });
        this.selectedGroupListBackup = [...this.selectedGroupList];
      }
    } else {
      // Remove Added Array..

      this.removeGroup(groupRow);
    }

  }




  //Remove Group
  removeGroup(removeRow) {
    let filterArray = this.selectedGroupList.filter(row => row['clientId'] != removeRow['clientId']);
    this.selectedGroupList = [];
    this.selectedGroupList = [...filterArray];
    this.selectedGroupListBackup = [...filterArray];
    let groupMainResponse = [];
    this.groupList = this.groupList.map(row => {
      if (row['clientId'] == removeRow['clientId']) {
        return { ...row, isChecked: false };
      } else {
        return row;
      }
    })

    setTimeout(() => {
      this.groupList$ = of([...this.groupList]);

    }, 0)
  }


  //isformValid
  domainEmpty = false;
  async isformValid() {
    let isValid = true;
    //
    if (this.groupName == "") {
      this.isGroupEmpty = true;
      isValid = false;
    }

    if (this.selectedGroupList.length <= 0) {
      this.isGroupListEmpty = true;
      isValid = false;
    }

    // Product List
    if (this.selectedProductList.length <= 0) {
      this.isProductEmpty = true;
      isValid = false;
    }

    if (this.selectedDomainArray.length <= 0) {
      isValid = false;
      this.domainEmpty = true;
    }
    // Return Value


    if (!isValid) {

      return false;
    } else {
      return true;
    }
  }
  // >> Submit Profile Form
  // >> Form Submit
  async submitProfile() {
    //@ Profile Form...
    //let formData = this.helper.formData(this.profileForm);
    // @Called
    //
    let self = this;
    this.submited = true;
    //is Valid ...
    let isValid = await this.isformValid();
    if (!isValid) {
      return false;
    }

    self.loader.start();
    this.requestGrpList();

    //Formate Array

    let reqObj = {
      "clientMapId":  this.clientMapId,
      "clientId": this.requestGroupList,
      "productId": this.selectedProductList,
      "groupName": this.groupName,
      "is_Active": true,
      "addUser": this.shareUtility.getUserId(),
      "LoggedInUser": this.shareUtility.getUserId(),
      "loginUserRole": this.shareUtility.getUserRole(),
      "domain": this.selectedDomainArray,
      "groupHighlight": true
    }

    // if Edit mode
    // >> Edit
    if (this.isEdit) {
      // Edit Profile
      reqObj['groupId'] = this.groupId;
      this.editGroup(reqObj);
    } else {
      // >> Add Mode...
      // >> Profile Add Mode...
      this.addGroup(reqObj);
    }

  }

  // Add Profile
  // >> Add Profile WIth DB
  addGroup(reqObj) {
    let self = this;
    self.sharedApi.AddNewClientGroup(reqObj).subscribe(response => {
      if (response && response['status'] == "Success") {


        let alreadyExistsMessage = "This Group is already exists. ";
        if (response['message'] != "" && response['message'].trim() == alreadyExistsMessage.trim()) {
          this.toaster.warning(response["message"]);
          this.loader.stop();
        } else {
          //this.toaster.success(response["message"]);
          setTimeout(() => {
            this.toaster.success(response["message"]);
            self.shareUtility.setGroupUpdate({ status: true });
            this.close({ status: false });
          }, 900)
        }

      } else {
        // this.close({ status: true });
        this.toaster.error(response["message"]);
        this.loader.stop();
      }
      //this.cl
    }, error => {
      // Error :
      this.toaster.error("Internal Server error!!");
      this.loader.stop();
      //
    })
  }

  // Update Profile Db
  // Edit Profile
  editGroup(reqObj) {
    let self = this;
    this.sharedApi.UpdateGroup(reqObj).subscribe(response => {
      // Response :
      //
      if (response && response['status'] == "Success") {


        let alreadyExistsMessage = "This Group is already exists. ";
        if (response['message'] != "" && response['message'].trim() == alreadyExistsMessage.trim()) {
          this.toaster.warning(response["message"]);
          this.loader.stop();
        } else {
        //  this.toaster.success(response["message"]);
          setTimeout(() => {
            this.toaster.success(response["message"]);
            self.shareUtility.setGroupUpdate({ status: true });
            this.close({ status: false });
          }, 900)
        }

      } else {
       // this.close({ status: true });
        this.toaster.error(response["message"]);
        this.loader.stop();
      }
      //this.cl
    }, error => {
      // Error :
      this.toaster.error("Internal Server error!!");
      this.loader.stop();
      //
    })
  }

  // >> Request Group

  requestGrpList() {

    //if (this.selectedGroupList.length > 0) {
    //  // Select Group
    //  this.selectedGroupList.map((row) => {
    //    // Row filter..
    //    this.requestGroupList.push(row['clientId']);
    //    this.clientMapId.push(row['clientMapId']);
    //  })
    //} else {
    //  // Requst...
    //  this.requestGroupList = [];
    //}

    //Work item number : 1816
    if (this.selectedGroupListBackup.length > 0) {
      // Select Group
      this.selectedGroupListBackup.map((row) => {
        // Row filter..
        this.requestGroupList.push(row['clientId']);
        this.clientMapId.push(row['clientMapId']);
      })
    } else {
      // Requst...
      this.requestGroupList = [];
    }

  }


  // >> Keyup Event
  groupNameKeyUp(event) {
    let value = event.target.value;
    value = value.trim();
    if (value && value.length > 0) {
      this.isGroupEmpty = false;
      this.groupName = event.target.value;
    } else {
      this.isGroupEmpty = true;
      this.groupName = "";

    }
  }

  onChangeProduct(e, productRow, index) {
    if (e['checked']) {
      this.isProductEmpty = false;
      this.selectedProductList.push(productRow['productId']);
    } else {
      // @Checked Product List...
      this.selectedProductList = this.selectedProductList.filter((obj) => obj != productRow['productId']);
     // this.selectedProductList.splice(index, 1);

    }
  }


  close(obj) {
    // If create Success Fully
    if (obj) {
      this.dialogRef.close(obj);
    } else {
      this.dialogRef.close({status: false});
    }

  }
}
