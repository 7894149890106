<div class="container-fluid static-page">
    <div class="row">
        <div class="col-lg-12">
            <h4>FundingShield LLC Internet Site Language For Articles, Reports and Legal Disclaimer</h4>
            <p>Please find below two separate disclaimers that should be considered as additions to FundingShield, LLC.'s website. The first disclaimer should be placed after each article or report while the other disclaimer should be added as hypertext to the website itself. </p>
            <p>The information provided in this article or report is only for general informational purposes. The information provided is not an individualized recommendation or personalized investment advice. The advice offered in this article may not be suitable for everyone. The reader should review any information in conjunction with his or her own particular situation and the reader should not rely upon any information in this article or report in making his or her decisions. The information contained in the article or report is obtained from what are considered reliable sources, but the accuracy, validity, timeliness, subject matter, quality, completeness or reliability cannot be guaranteed by either the FundingShield, LLC. or the author. All such information is subject to change at any time and without prior notice. Additionally, this is not an offer to sell any securities, services, funds or programs in any jurisdiction in which either securities, services, funds, or programs cannot be legally offered or sold, or in which FundingShield, LLC., or its affiliates or subsidiaries are not legally qualified to offer or sell securities, funds or programs. Further, no services are being offered nor are available to citizens or residents of any nation outside the United States or in the United States.</p>
            <h4>The Legal Disclaimer</h4>
            <p>FundingShield, LLC. only provides the information on this website for general informational purposes. This website does not create, establish or implicate a client, advisory, fiduciary, professional, auditing, accounting, tax, legal or consulting relationship. Further, this site should not be considered to offer auditing, accounting, tax, legal, consulting, investment or other professional advice. This website or its contents are also not substitutes for the reader seeking out independent professional advice from a source that is aware of the reader's individual factual situation and circumstances. As such, FundingShield, LLC. does not intend for this information contained on it to address individual problems, matters, or situations concerning persons, entities, corporations, partnerships, companies or other business combinations.</p>
            <p>Although FundingShield, LLC. has made every effort to ensure that the information contained on this website was obtained from reliable sources, it does not currently or in the future guarantee the accuracy, timeliness, completeness, or reliability of the information provided on its website. Further, FundingShield, LLC. is not responsible for any errors, omissions, mistakes or for the results obtained by using this website. Accordingly, FundingShield, LLC., its affiliates, partners, principals, employees, agents and representatives make no representations or warranties about the accuracy, timeliness, suitability or any other aspect of this website or any content on or accessed through this website for any purpose. The information on this website is solely provided "as is" and without any warranty of any kind. FundingShield, LLC. hereby expressly disclaims all warranties and obligations regarding this website or any content on or accessed through this website, including, but not limited to, all implied warranties and obligations of merchantability, fitness for a particular purpose, title, performance and non-infringement. FundingShield, LLC., its affiliates, partners, principals, employees, agents and representatives are not liable in any event for any direct, indirect, incidental, special, exemplary, consequential or any other damages (including, but not limited to, liability for loss of use, data or profits), without regard to the form of any action, including, but not limited to, contract, negligence or other tort claims, arising out of or in connection with this website, any content on or accessed through this website, or any copying display or other use. </p>
            <p>All hyperlinks provided through this website to other websites are maintained by third parties, that is, entities or persons who are not controlled by FundingShield, LLC.. FundingShield, LLC. makes no representations as to the accuracy or any other aspect of information contained in those other websites. </p>
            <p>Finally, FundingShield, LLC. maintains this website in New York, New York, U.S.A. FundingShield, LLC. does not make any representations that this website or its contents are appropriate or suitable for use in other jurisdictions. The reader is solely responsible for ensuring his or her compliance with all applicable laws and regulations.</p>
            <h4>Service Standards, Terms and Conditions of Use</h4>
            <p>Nothing in the editorial content on FundingShield, LLC. should be interpreted as a recommendation to purchase or sell any product, or to make any other decision with a financial impact. We may provide hypertext links to such information that may be provided by our sponsors, but we are not responsible for the content of their material, and our affiliation with them as sponsors should not be interpreted as an endorsement by FundingShield, LLC. of their products or services. </p>
            <p>Likewise, we cannot promise that incorrect information will not appear on FundingShield, LLC., and we are not liable for any damages caused by the appearance of incorrect information on FundingShield, LLC., whether this information is provided directly by us, a sponsor, or other information provider. </p>
            <p>All use and access of the information (the "Information") furnished by FundingShield, LLC. is subject to the terms and conditions as set forth in these service standards. Any use of such Information constitutes the user's agreement to abide by these terms and conditions. All Information provided by FundingShield, LLC. is owned by or licensed to FundingShield, LLC. or credited to the providing organization(s). FundingShield, LLC. retains all proprietary rights to the Information. Except for making one hard copy print of limited portions of the Information on an ad hoc basis, or downloading as expressly authorized by FundingShield, LLC., the Information may not be reproduced, transmitted, posted to another Web site, or distributed without FundingShield, LLC.'s permission.</p>
            <p>FundingShield, LLC. makes no guarantees or warranties as to the accuracy or completeness of results to be obtained from accessing and using the Information. FUNDINGSHIELD, LLC. EXPRESSLY DISCLAIMS ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR USE. THE INFORMATION IS PROVIDED AS IS, WITH ALL FAULTS. FundingShield, LLC. shall not be liable to any user or anyone else for any inaccuracy, error or omission, regardless of cause, in the Information or for any damages resulting therefrom. In no event shall FundingShield, LLC. be liable for an consequential damages suffered by user as the result of user's access to or use of the Information. FundingShield, LLC. has no responsibility for the content of any messages or information posted by users, or for the content of information of third parties on the Internet, even if accessed through FundingShield, LLC.'s site. The user agrees to indemnify FundingShield, LLC. from any damages, losses, costs, or expenses which FundingShield, LLC. and its authorized representatives may incur as a result of material entered into these World Wide Web pages by the user. </p>
            <p>For Potential Sponsors, Clients, and Business Partners: FundingShield, LLC. does warrant that the Information contained within its online media kit and other collateral materials is, to the best of our knowledge, truthful and correct. Representations of past performance, however, are not meant, and cannot be read, as a guarantee that similar results will be achieved by any other advertising campaigns, syndication of content, or any other services that might be rendered by FundingShield, LLC.. FundingShield, LLC. does guarantee that we will provide the highest level of service possible to our business partners, and that we will do everything in our power to help them achieve the goals they aspire to through their business relationships with FundingShield, LLC.</p>
        </div>
    </div>
</div>
