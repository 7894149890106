import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { Observable, of } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/forms/form.service';
import { Helper } from '../../classes/helpers.class';
import { Utility } from 'src/app/async/utility';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrls: ['./new-profile.component.css']
})
export class NewProfileComponent implements OnInit {
  // defined the array of data

  public localData: Object[] = [];
  public field: Object = { dataSource: this.localData, id: 'clientMapId', parentID: 'parentMapId', text: 'clientName', hasChildren: 'hasChild' };
  public cssClass: string = "custom";
  setGroupChangeInterval: any;

  isProfileType = false;
  //Title Default
  title = "Add New Profile";
  //Is Edit Mode
  isEdit = false;
  //Profile Id For Edit Mode..
  profileId;
  // Product Lit
  productList$: Observable<any> = of([]);
  productList = [];
  productListBcp = [];
  groupList$: Observable<any> = of([])
  groupList = [];
  //Selected Domain Array
  selectedDomainArray = [];
  //Domain Url
  domainUrl = "";
  isDomainValid = false;
  selectedGroupList = [];
  requestGroupList = [];
  //Form group
  profileForm: FormGroup;
  //@Helper Load Type Inject
  helper: Helper = new Helper();

  // @Product List
  reqProductList = [];

  // Client Group Id
  clientGroupId;

  // @LABEL NAME:
  sideBarLabel = "All Selected Group";
  leftSidebarLabel = "Select Group";
  isFsAdminProfileSelect = true;
  groupListBackup = [];
  selectedGroupListBcp = [];

  oldCheckedProductList = [];

  // Client Group Id
  profileTypeId = '';
  //
  selectedGroupId$: Observable<any> = of("");
  ProductListSelected = [];
  RequestClientList = [];


  // Vslidation
  isProfileNameEmpty = false;
  submited = false;
  isProductEmpty = false;
  isCompanyEmpty = false;
  seletedCompanyList = [];
  // is Group
  isGroupEmpty = false;
  // is group
  localDataBup = [];

  ProductListSelectedBcp = [];
  isViewProfile = false;
  isSpeener$: Observable<boolean> = of(true);
  constructor(
    public shareUtility: Utility,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sharedApi: SharedService,
    public toaster: ToastrService,
    private dialogRef: MatDialogRef<NewProfileComponent>,
    public loader: NgxUiLoaderService,
    private fs: FormService) {
    // Called To Inhired Form
    this.loader.start();
    this.profileForm = fs.newProfileForm();
    this.setGroupChangeInterval = null;
    //Helper


  }



  // Is Profile KeyUp

  isProfileKeyUp(val) {
    if (val && val.length > 0) {
      this.isProfileNameEmpty = false;
    } else {
      this.isProfileNameEmpty = true;
    }
  }

  // >> Group List
  // >> Filter Grid...
  groupFilter(value) {
    //
    if (value && value.length > 0) {
      if (this.groupList.length > 0) {
        let str = value.toLowerCase();
        this.groupList = this.groupList.filter((obj) => obj['groupName'].toLowerCase().indexOf(str) > -1);
        this.groupList$ = of(this.groupList);

      } else {
        this.groupList = this.groupListBackup;
        this.groupList$ = of(this.groupList);
      }
    } else {
      this.groupList = this.groupListBackup;
      this.groupList$ = of(this.groupList);
    }
  }

  // >> Remove Filter Grid
  selectedGroupFilter(value) {
    if (value && value.length > 0) {
      if (this.groupList.length > 0) {
        let str = value.toLowerCase();
        this.selectedGroupList = this.selectedGroupList.filter((obj) => obj['groupName'].toLowerCase().indexOf(str) > -1);
      }
    } else {
      this.selectedGroupList = this.selectedGroupListBcp;
    }
  }



  //@Company Filter

  companyFilter(value) {
    if (value && value.length > 0) {
      if (this.localData.length > 0) {
        this.localData = this.localDataBup;
        let str = value.toLowerCase();
        this.localData = this.localData.filter((obj) => obj['clientName'].toLowerCase().indexOf(str) > -1);
        this.field = { dataSource: this.localData, id: 'clientMapId', parentID: 'parentMapId', text: 'clientName', hasChildren: 'hasChild' };
      }
    } else {
      // Local Data
      this.localData = this.localDataBup;
      this.field = { dataSource: this.localData, id: 'clientMapId', parentID: 'parentMapId', text: 'clientName', hasChildren: 'hasChild' };
    }
  }

  // >> Product Filter

  productFilter(value) {
    if (value && value.length > 0) {
      if (this.groupList.length > 0) {
        let str = value.toLowerCase();
        this.productList = this.productList.filter((obj) => obj['productName'].toLowerCase().indexOf(str) > -1);
      }
    } else {
      this.productList = this.productListBcp;
    }
  }

  // Filter
  // Company Filter
  companyFilterSelectedSearch(value) {
    if (value && value.length > 0) {
      if (this.ProductListSelected.length > 0) {
        let str = value.toLowerCase();
        this.ProductListSelected = this.ProductListSelected.filter((obj) => obj['clientName'].toLowerCase().indexOf(str) > -1);
      }
    } else {
      // Local Data
      this.ProductListSelected = this.ProductListSelectedBcp;
    }
  }
  ngOnInit() {
    //IsMode method called for Default Render Data with UI
    this.isMode();
  }

  //Inital Render .....
  //Profile Select
  profileTypeChange(event) {
    // @Fs Admin Select
    if (event['value'] == 'FS Admin') {
      this.isFsAdminProfileSelect = true;
    } else {
      this.isSpeener$ = of(false);
      this.isFsAdminProfileSelect = false;
    }

  }
  //This Method used For Check Operation Mode
  //Like IS UI Open For Add or Edit Mode
  isMode() {
    // If UI Open For Edit Mode Then

    if (this.data && this.data['status'] == 'edit_profile') {
      this.title = "Edit Profile";
      this.editRender();
    } else if (this.data && this.data['status'] == 'view_profile') {
      this.isViewProfile = true;
      this.title = "View Profile";
       this.editRender();
    } else {
      this.addNewRender();
      this.setForm();
    }
  }

  //@Edit Render Method while Open Default Method
  // Get Here Profile Info Also
  editRender() {
    this.isEdit = true;
  //
    this.profileId = this.data['value']['profileId'];
    // Set Form

    this.getProfile(this.profileId)
    // Get Profile List
   // this.getProducts(this.profileId);
    this.GetProductListForProfile(null, this.profileId)
    //Get Groups

    this.GetGroupByProfileId(this.profileId);
    // Get Product List
    this.setForm(this.data['value']);
    this.profileForm.get('profiletype').disable();


  }
  //Get Group Domain By Id
  getDomain(groupId) {
    let reqObj = {
      LoggedInUser: localStorage.getItem('userKey'),
      GroupKey: groupId
    };
    this.sharedApi.GetDomainByGroupId(reqObj).subscribe(resp => {
      if (resp && resp['status'] == "Success") {
        this.loader.stop();
        this.selectedDomainArray = resp["value"];
      }
      this.groupFlag.domain = true;
      this.chkGroupDataRes();
    }, error => {
      this.groupFlag.domain = true;
      this.chkGroupDataRes();
      this.loader.stop();
      //
    })
  }
  setForm(data = null) {
    if (data) {
      if (data['profileTypeName'] == "Client Admin") {
        this.isFsAdminProfileSelect = false;
        this.profileTypeId = data['profileTypeId'];
      }
      this.profileForm.patchValue({ profileName: data['profileName'], profiletype: data['profileTypeName'] });
    } else {
      //@ Fs Admin
      let userLogin = this.shareUtility.getUserRole();
      //@Client Admin
      if (userLogin == "Client Admin" || userLogin == "Client Admin Manager") {
        this.isSpeener$ = of(false)
        this.isFsAdminProfileSelect = false;
        this.profileForm.patchValue({ profiletype: "Client Admin" });
        this.profileForm.get("profiletype").disable();
      } else {
        this.isFsAdminProfileSelect = true;
        this.profileForm.patchValue({ profiletype: "FS Admin" });
      }

    }

  }
  //@New Profile Function while called
  //@Default Method
  addNewRender() {
  //  this.getProducts();
    //Get Groups

    this.getGroups();

    // Product List
    this.getProductList();
    // Get Client List For Client Admin
 //   this.GetClientById();
  }



  //@Method Get Called In Side....
  // >> Get Profile Data ..
  // >> Get Profile Record

  getProfile(ProfileId) {
    let reqObj = {
       ProfileId: this.profileId,
     // ProfileId: 4,
      LoggedInUser: localStorage.getItem('userKey'),
      GroupId: null
    }
    this.sharedApi.GetProfileDetailById(reqObj).subscribe(resp => {
     //
      if (resp && resp['status'] == "Success") {
         // Domain Exists Then
        let result = resp['value'];
        if (result && result['domain'] && result['domain']!="") {
          this.selectedDomainArray = result['domain'].split(";");

        }
        this.setForm(resp['value']);
       //
        this.clientGroupId = result['groupId'] || null;
        this.selectedGroupId$ = of(result['groupId']);
        this.profileForm.get('clientGroupName').patchValue(this.clientGroupId);
        this.profileForm.get('clientGroupName').disable();


        //
        this.GetClientById(this.profileId)
        //groupId
      }
    }, error => {
      //
    })
  }
  //Get Profile By Id
  // Get All Profile Response based on Profile Id
  getProducts(ProfileId = null) {
    let reqObj = {};
    if (ProfileId) {
      // Profile Id get automatic
      reqObj['ProfileId'] = ProfileId;
      reqObj['LoggedInUser'] = localStorage.getItem('userKey');
      reqObj['GroupId'] = null;
    } else {
      reqObj['ProfileId'] = null;
      reqObj['LoggedInUser'] = localStorage.getItem('userKey');
      reqObj['GroupId'] = null;
    }

    this.sharedApi.GetProductList(reqObj).subscribe(resp => {
      if (resp && resp['status'] == "Success") {
        this.productList$ = of(resp["value"]);
        this.productList = [...resp["value"]];
        this.productListBcp = [...resp["value"]];
        //
        // Selected Product List
        if (this.isEdit) {
          // setUpdateProductIds
          this.setUpdateProductIds(resp["value"]);
        }
      }
    }, error => {
     //
    })
  }

  setUpdateProductIds(array) {
    if (array && array.length > 0) {
      // Map Row
      array.map((row) => {
        if (row['productHighlight']) {
          //Row Select Row if True Then
          this.selectedProductList.push(row['productId'])
        }
      })
    }

  }




  // >> selectProductList
  selectGroupList() {
    let res = [];
    this.groupList.map(row => {
      if (row['groupHighlight']) {
        this.selectedGroupList.push(row);
        this.selectedGroupListBcp.push(row);
        res.push({ ...row, isChecked: true });
      } else {
        res.push({ ...row, isChecked: false });
      }

    });

    // setTimeout...
    setTimeout(() => {
     // this.selectedGroupList = res;
      this.groupList$ = of(res);
      this.groupList = res;
      this.groupListBackup = res;

    }, 2000)
  }




  //Get All Group List
  GetGroupByProfileId(profileId) {

    let reqObj = {
      ProfileId: profileId,
      // ProfileId: 4,
      LoggedInUser: this.shareUtility.getUserId(),
      LoginUserRole: this.shareUtility.getUserRole()
    };
    this.sharedApi.GetGroupByProfileId(reqObj).subscribe(resp => {
      if (resp && resp['status'] == "Success") {
        // 
        this.loader.stop();
        this.groupList$ = of(resp["value"]);
        this.groupList = resp["value"];
        this.groupListBackup = resp["value"];
        this.selectGroupList();
      } else {
        this.loader.stop();
      }
    }, error => {
      //
      this.loader.stop();
    })

  }

  //Get Group List By Id
  // Get All Group Response based on
  getGroups(productId = null) {

  let reqObj = {
      LoggedInUser: localStorage.getItem('userKey'),
      GroupId: null,
      status: "AddNewProfileClientGroup"
    }
    this.sharedApi.GetClientGroupList(reqObj).subscribe(resp => {
      if (resp && resp['status'] == "Success") {
        this.loader.stop();
        this.groupList$ = of(resp["value"]);
        this.groupList = resp["value"];
        this.groupListBackup = resp["value"];

        // Edit ..
        if (this.isEdit) {
          this.selectGroupList();
        }


        // If Client Admin User Login
        let userLogin = this.shareUtility.getUserRole();
        //@Client Admin
        if (!this.isEdit && (userLogin == "Client Admin" || userLogin == "Client Admin Manager") && this.groupList && this.groupList.length>0) {
          this.profileForm.get("clientGroupName").patchValue(this.groupList[0]['groupId']);
          this.clientGroupId = this.groupList[0]['groupId'];
          //info
          this.isSpeener$ = of(true)
          //
          this.GetClientById(this.profileId);
          this.GetProductListForProfile(this.clientGroupId);
          this.getDomain(this.clientGroupId);
        }
      } else {
        this.loader.stop();
      }
    }, error => {
      this.loader.stop();
    })
  }

  domainKeyUp(event) {
    // Url Check
    // Regular Expression ...
    let isUrlValid  =  (userInput) => {
      var regexQuery = "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,100}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
      var url = new RegExp(regexQuery, "i");
      var regexQuery2 = "^\\..[a-zA-Z0-9]{1,}";
      var url2 = new RegExp(regexQuery2, "i");
      return url.test(userInput) || url2.test(userInput);
    }
    // Feature..
    // key paier value..
    let keyUPValue = event['target']['value'];
    // Check Url Valid...
    if (isUrlValid(keyUPValue)) {
      // Domain true ...
      this.isDomainValid = true;
      this.domainUrl = keyUPValue;
    } else {
      // is Domain Valid
      this.isDomainValid = false;
    }
  }


  // >> On Click Add Button On Domain Add
  // >> Added Domain New
  domainAdd() {
    this.domainEmpty = false;
    if (this.selectedDomainArray.length > 0) {
      let findObj = this.selectedDomainArray.find((obj) => obj == this.domainUrl);
      if (!findObj) {

        this.selectedDomainArray.push(this.domainUrl);
      }
    } else {
      this.selectedDomainArray.push(this.domainUrl);
    }
    document.getElementById("domain_input")["value"] = "";
    this.isDomainValid = false;
  }

  //Product Change
  //Product Key Value
  selectedProductList = [];
  onChangeProduct(e,productRow,index) {
    if (e['checked']) {
      this.isProductEmpty = false;
      this.selectedProductList.push(productRow['productId']);
     //
    } else {
      // @Checked Product List...
      this.selectedProductList = this.selectedProductList.filter((obj) => obj != productRow['productId']);
    }
  }


  // Group Change
  // On Change Group
  onChangeGroup(e, groupRow, index) {

    if (e['checked']) {
      if (this.selectedGroupList.length > 0) {
        let findObj = this.selectedGroupList.find((row) => row['groupId'] == groupRow['groupId']);
      //
        if (!findObj) {
          this.selectedGroupList.push({ ...groupRow, isChecked: true });
          this.selectedGroupListBcp = [...this.selectedGroupList];
          this.groupList[index]['isChecked'] = true;
        }
      }

      // length if 0 then is called
      else {
        this.isGroupEmpty = false;
        this.groupList[index]['isChecked'] = true;
        this.selectedGroupList.push({ ...groupRow, isChecked: true });
        this.selectedGroupListBcp.push({ ...groupRow, isChecked: true });
      }
    } else {
      // Remove Added Array..
      this.removeGroup(groupRow)
    }

  }

  //Remove Group
  removeGroup(removeRow) {
    //
    let filterArray = this.selectedGroupList.filter(row => row['groupId'] != removeRow['groupId']);
    this.selectedGroupList = [];
    this.selectedGroupListBcp = [];
    this.selectedGroupList = [...filterArray];
    this.selectedGroupListBcp = [...filterArray];
    let groupMainResponse = [];
    this.groupList.map(row => {
      if (row['groupId'] == removeRow['groupId']) {
        groupMainResponse.push({ ...row, isChecked: false });
      } else {
        groupMainResponse.push({ ...row });
      }
    })

    setTimeout(() => {
      this.groupList$ = of(groupMainResponse);
      this.groupList = [...groupMainResponse];
    },500)
  }



  domainEmpty = false;
  async isformValid() {
     //
    let profileName = this.profileForm.get('profileName').value;
    let isValid = true;
    if (profileName == "") {
      this.isProfileNameEmpty = true;
      isValid = false;

    }
    //@Group Validation..
    if (this.selectedGroupList.length <= 0 && this.isFsAdminProfileSelect) {
      this.isGroupEmpty = true;
       isValid = false;
    }

     //Product List Validation
     if (this.selectedProductList.length <= 0 && !this.isFsAdminProfileSelect) {
       isValid = false;
       this.isProductEmpty = true;
     }

     // Client Valid
     if (this.ProductListSelected.length <= 0 && !this.isFsAdminProfileSelect) {
       isValid = false;
       this.isCompanyEmpty = true;
    }

    if (this.selectedDomainArray.length <= 0 && !this.isFsAdminProfileSelect) {
      isValid = false;
      this.domainEmpty = true;
    }

     if (!isValid) {
       return false;
     } else {
       return true;
     }
  }
  // >> Submit Profile Form
  // >> Form Submit
 async  submitProfile() {
    this.submited = true;
    //is Valid ...

   let isValid = await this.isformValid();
   if (!isValid) {
      return false;
    }
    //@ Profile Form...
    this.loader.start();
    let formData = this.helper.formData(this.profileForm);
    // @Called
    this.requestGrpList();

    //
    this.reqClientGroupList();

    // Called ReqObj Return Value
   let reqObj = this.reqObjFunction(formData);

    //
    // if Edit mode
    // >> Edit
    if (this.isEdit) {
      // Edit Profile
      this.editProfile(reqObj);
    } else {
      // >> Add Mode...
      // >> Profile Add Mode...
      this.addProfile(reqObj);
    }

  }

  //Get Resposne List
  //@Resposne
  getProductList(profileId = null) {
    let reqObj = {
      LoggedInUser: localStorage.getItem('userKey'),
      ProfileId: null
    }
    this.sharedApi.GetProductListByProfile(reqObj).subscribe(response => {
      // Response :
      //
      if (response && response['status'] == "Success") {
        //
      } else {

      }
      //this.cl
    }, error => {
      // Error :
      //
    })
  }

  // >> ReqObj
  reqObjFunction(formData) {

    let clientId = "";
    let profilTypeName = this.isFsAdminProfileSelect ? "FS Admin" : "Client Admin";
    let userLogin = this.shareUtility.getUserRole();
    //@Client Admin
    if (userLogin == "Client Admin" || userLogin == "Client Admin Manager") {
      profilTypeName = "Client Admin";
    }
    let reqObj = {
      "profileId": (this.profileId!="") ? this.profileId : undefined,
      "profileName": formData['profileName'],
      "profileTypeId": this.profileTypeId,
      "profileTypeName": profilTypeName,
      "isActive": true,
      "addUser": this.shareUtility.getUserId(),
      "LoggedInUser": this.shareUtility.getUserId(),
     // "updateUser": "string",
      //"updateTime": "string",
      "clientGroupId": (this.requestGroupList.length > 0 && this.isFsAdminProfileSelect) ? _.uniq(this.requestGroupList).join(";"): "",
      "groupId": this.clientGroupId,
      "clientMapId": (this.RequestClientList.length > 0 && this.RequestClientList) ? _.uniq(this.RequestClientList).join(";") : "",
      "domain": ( this.selectedDomainArray.length > 0 && !this.isFsAdminProfileSelect) ? this.selectedDomainArray.join(";") : '',
      "productId": (this.selectedProductList.length > 0 && !this.isFsAdminProfileSelect) ? this.selectedProductList.join(";") : '',
      "loginUserRole": this.shareUtility.getUserRole(),
      "oldCheckedProductList": (this.oldCheckedProductList.length > 0 && this.oldCheckedProductList) ? _.uniq(this.oldCheckedProductList).join(";") : "",
     // "userCount": 0

    }



    // @ return
    return reqObj;
  }

  //ClientMap Id
  // Add Profile
  // >> Add Profile WIth DB
  addProfile(reqObj) {
    this.sharedApi.AddProfile(reqObj).subscribe(response => {
      // Response :
      //
      if (response && response['status'] == "Success") {
        let alreadyExistsMessage = "Profile Name already exists. ";
        if (response['message'] != "" && response['message'].trim() == alreadyExistsMessage.trim()) {
          this.toaster.warning(response["message"]);
          this.loader.stop();
        } else {
          this.toaster.success(response["message"]);
          this.shareUtility.setProfileUpdate({ status: true });
          this.close({ status: true });
        }
      } else {
        this.toaster.error("Internal server error!");
        this.loader.stop();
      }
      //this.cl
    }, error => {
      // Error :
      //
    })
  }

  // Update Profile Db
  // Edit Profile
  editProfile(reqObj) {
    let self = this;
    self.sharedApi.UpdateProfile(reqObj).subscribe(response => {
      // Response :
      //
      if (response && response['status'] == "Success") {
        let alreadyExistsMessage = "Profile Name already exists. ";
        if (response['message'] != "" && response['message'].trim() == alreadyExistsMessage.trim()) {
          self.toaster.warning(response["message"]);
          self.loader.stop();
        } else {
          self.toaster.success(response["message"]);
          self.shareUtility.setProfileUpdate({ status: true });
          self.close({ status: true });
        }

      } else {
        self.loader.stop();
        self.toaster.error(response["message"] || "Internal server error!");
      }
      //this.cl
    }, error => {
      // Error :
     //
    })
  }
  // >> Remove Domain Index...
  // >> Selected Domain Array Remove Index..
  removeDomain(index) {
    this.selectedDomainArray.splice(index, 1);
  }

  // >> Close The Pop up closed removed.
  // >> close and distroyed pop..
  close(obj = {status: false}) {
    // If Obj
    this.dialogRef.close(obj);
  }

  // >> Request Group
  requestGrpList() {
    if (this.selectedGroupList.length > 0) {
      // Select Group.
      this.selectedGroupList.map((row) => {
        // Row filter..
        this.requestGroupList.push(row['groupId']);
      })
    } else {
      // Requst...
      this.requestGroupList = [];
    }
  }

  groupFlag = { client: false, product: false, domain: false };


  groupChangeValue(event) {
    let self = this;
    if (event && event['value'] != "") {
      this.clientGroupId = event['value'];
   //info
      this.isSpeener$ = of(true)
      //

      this.groupFlag = { client: false, product: false, domain: false };
      this.profileForm.get('clientGroupName').disable();
      window.clearInterval(self.setGroupChangeInterval);

      this.GetClientById(this.profileId);
      this.GetProductListForProfile(event['value']);
      this.getDomain(event['value']);


     // self.setGroupChangeInterval = setInterval(function () {

      //},1000);

    }
  }


  chkGroupDataRes() {
    let self = this;
    if (self.groupFlag.client && self.groupFlag.domain && self.groupFlag.product) {
      self.profileForm.get('clientGroupName').enable();
       self.isSpeener$ = of(false);
     // window.clearInterval(self.setGroupChangeInterval);
    }
  }

  //GetProductListForProfile
  GetProductListForProfile(GroupId = null, profileId = null) {


    let reqObj = {
      ProfileId: profileId ,
      LoginUserRole: this.shareUtility.getUserRole(),
      azureUserKey: localStorage.getItem('userKey_b2c'),
      LoggedInUser: this.shareUtility.getUserId(),
      GroupId: GroupId
    };

    // App Client Get
    this.sharedApi.GetProductListForProfile(reqObj).subscribe(response => {
      // Response :
      //
      if (response && response['status'] == "Success") {
        this.productList$ = of(response["value"]);
        this.productList = [...response["value"]];
        this.productListBcp = [...response["value"]];
        //

        this.setUpdateProductIds(response["value"]);



        this.oldCheckedProductList = this.productListBcp.filter((row) => row['productHighlight'] == true).map(i=>i.productId);

      }

      this.groupFlag.product = true;
      this.chkGroupDataRes();
      //this.cl
    }, error => {
      this.groupFlag.product = true;
      this.chkGroupDataRes();
      // Error :
      //
    })
  }
  //Client Grid

  GetClientById(profileKey = null) {
    this.field = { dataSource: null, id: '', parentID: '', text: '', hasChildren: '' };

    let reqObj = {
      profileKey,
      LoginUserRole: this.shareUtility.getUserRole(),
      azureUserKey: localStorage.getItem('userKey_b2c'),
      LoggedInUser: this.shareUtility.getUserId(),
      GroupKey: this.clientGroupId
    };

    // App Client Get
    this.sharedApi.GetClientById(reqObj).subscribe(response => {
      // Response :
      //
      if (response && response['status'] == "Success") {
        if (response['value']) {
          //
          this.isSpeener$ = of(false);
          this.localData = response['value'];
          this.localData = this.localData.map(row => {
            return { ...row, expanded: true };
          });

          this.localDataBup = [...response['value']];
          this.selectedClientList(response['value']);
          this.field = { dataSource: this.localData, id: 'clientMapId', parentID: 'parentMapId', text: 'clientName', hasChildren: 'hasChild' };
        }
        else {
          this.localData = null;

          this.field = { dataSource: this.localData, id: '', parentID: '', text: '', hasChildren: '' };

        }
      } else {
        this.isSpeener$ = of(false);
      }
      this.groupFlag.client = true;
      this.chkGroupDataRes();
      //this.cl
    }, error => {
      this.groupFlag.client = true;
      this.chkGroupDataRes();
      this.isSpeener$ = of(false);
      // Error :
      //
    })
  }

  // companyListAsync(id)
  companyListAsyncRemove(id) {
    let node = []
    let self = this;
    //
    var sortDirectories = function (directories, parent) {
      directories
        .filter(function (d) { return d.parentMapId === parent })
        .forEach(function (d, index) {
          var cd = [];
          var dc = [];

          node.push(d);
          if (self.ProductListSelected && self.ProductListSelected.length > 0) {
            self.ProductListSelected = self.ProductListSelected.filter((findRow) => findRow['clientMapId'] != d['clientMapId']);
            self.ProductListSelectedBcp = [...self.ProductListSelected];
          } else {
            self.ProductListSelected = [];
            self.ProductListSelectedBcp = [];
          }

            self.localData = self.localData.map((row) => {
              if (row['clientMapId'] == d['clientMapId']) {
                return { ...row, highlight: false, expanded: true };
              } else {
                return { ...row, expanded: true  }
              }
            });
            setTimeout(() => {
              self.field = { dataSource: self.localData, id: 'clientMapId', parentID: 'parentMapId', text: 'clientName', hasChildren: 'hasChild' };
            }, 600)
       //   }

          //has child
          if (d.hasChild) {
            sortDirectories(directories, d.clientMapId)
          }
          return node
        })
      return node;
    }
    let result = sortDirectories(this.localData, id);
     console.log(result)
  }
  // companyListAsync(id)
  companyListAsync(id) {
    let node = []
    let self = this;
    var sortDirectories = function (directories, parent) {
      directories
        .filter(function (d) { return d.parentMapId === parent })
        .forEach(function (d, index) {
          var cd = [];
          var dc = [];

          node.push(d);
          let findExists = self.ProductListSelected.find((findRow) => findRow['clientMapId'] == d['clientMapId']);
          if (!findExists) {
            self.ProductListSelected.push({ ...d, highlight: true, expanded: true})
            self.ProductListSelectedBcp = [...self.ProductListSelected];
            self.localData = self.localData.map((row) => {
              if (row['clientMapId'] == d['clientMapId']) {
                return { ...row, highlight: true, expanded: true};
              } else {
                return { ...row }
              }
            });
            setTimeout(() => {
              self.field = { dataSource: self.localData, id: 'clientMapId', parentID: 'parentMapId', text: 'clientName', hasChildren: 'hasChild' };
            }, 600)
          }

          //has child
          if (d.hasChild) {
            sortDirectories(directories, d.clientMapId)
          }
          return node
        })
      return node;
    }
    let result = sortDirectories(this.localData, id);
   // console.log(result)
  }

  async changeCheckbox(event, data) {
   //
    let self = this;
    if (event['checked']) {
          this.ProductListSelected.push(data);
          this.ProductListSelectedBcp = [...this.ProductListSelected];
          let response = [];
          // Local data
          if (data['hasChild']) {
            this.companyListAsync(data['clientMapId']);
          }
          this.isCompanyEmpty = false;
            this.localData =  this.localData.map((obj) => {
              if (obj['clientMapId'] == data['clientMapId']) {
                response.push({ ...obj, highlight: true });
                return { ...obj, highlight: true, expanded: true };
              } else {
                response.push({ ...obj });
               return  { ...obj}
              }
            });


    } else {

          let isParentChecked = await this.isParentChecked(data);
      if (isParentChecked) {
          //
            this.ProductListSelected = this.ProductListSelected.filter((row) => row['clientMapId'] != data['clientMapId']);
            this.ProductListSelectedBcp = [...this.ProductListSelected];
            let response = [];

            this.localData = this.localData.map((obj) => {
              if (obj['clientMapId'] == data['clientMapId']) {
                return { ...obj, highlight: false, expanded: true };
              } else {
                response.push({ ...obj, expanded: true });
                return { ...obj, expanded: true };
              }
            });
            if (data['hasChild']) {
              this.companyListAsyncRemove(data['clientMapId']);
            }
      } else {
            self.localData = self.localData.map((obj) => {
              if (obj['clientMapId'] == data['clientMapId']) {
                return { ...obj, highlight: true, expanded: true };
              } else {
                return { ...obj, expanded: true };
              }
            });
            self.toaster.info("Please unchecked parent and then try it again.");
            self.field = { dataSource: self.localData, id: 'clientMapId', parentID: 'parentMapId', text: 'clientName', hasChildren: 'hasChild' };
            return false;
          }

    }
  }


  async isParentChecked(data) {
    //
    if (data['parentMapId'] == "" || data['parentMapId'] == null || !data['parentMapId']) {
      return true;
    } else {
      let isParentObj = this.localData.find(row => row['clientMapId'] == data['parentMapId']);
      if (isParentObj && !isParentObj['highlight']) {
        return true;
      } else {
        return false;
      }
    }

  }

  removeClientGroup(data) {
    //
    let selectedArray = this.ProductListSelected.filter((row) => row['clientMapId'] != data['clientMapId']);
    this.ProductListSelected = selectedArray;
    this.ProductListSelectedBcp = [...this.ProductListSelected];
    let resultFormate = [];
    this.localData.map((row) => {
      if (row['clientMapId'] == data['clientMapId']) {
        resultFormate.push({ ...row, highlight: false });
      } else {
        resultFormate.push({ ...row });
      }
    });

    setTimeout(() => {
      this.localData = [...resultFormate];
      this.field = { dataSource: this.localData, id: 'clientMapId', parentID: 'parentMapId', text: 'clientName', hasChildren: 'hasChild' };
      console.error(this.localData)
    }, 2000);
   /// let filterArray = this.
  }

  reqClientGroupList() {
    this.ProductListSelected.map(row => {
      this.RequestClientList.push(row['clientMapId']);
    })
  }




  //@Selected Client List
  // Client List..
  selectedClientList(array) {
    if (array && array.length > 0) {
      //Product List Area Called
      this.ProductListSelected = array.filter(obj => obj['highlight'] == true)
    }
  }
}
