
export const USER: string = "XSRF-OID";
export const constant = {
  auth: 'auth',
  cache: 'cache',
  fake: 'fake',
  error: 'error',
  profiler: 'profiler',
  notify: 'notify',
  header: 'header',
  convert: 'convert',
  loader: 'loader',
  https: 'https',
  pending_color: '#899093',
  success_color: '#6cca5c',
  rejected_color: '#fc3232',
  contactUs: 'https://www.fundingshield.com/#Contact',
  tabsOrders: [
   {
    label: 'client_info',
    index : 0,
    text: 'Client Info'
   },
   {
    label: 'borrower_info',
    index : 1,
    text: 'Borrower Info'
   },
   {
    label: 'porperty_info',
    index : 2,
    text: 'Property Info'
   },
   {
    label: 'loan_info',
    index : 3,
    text: 'Loan Info'
   },
   {
    label: 'closing_agent_info',
    index : 4,
    text: 'Closing Agent Info'
   },
   {
    label: 'contact_info',
    index : 5,
    text: 'Contact Info'
   },
   {
    label: 'wire_info',
    index : 6,
    text: 'Wire Info'
   },
   {
    label: 'underwriter_info',
    index : 7,
    text: 'Underwriter Info'
   },
   {
    label: 'upload_doc',
    index : 8,
    text: 'Upload Doc'
   }
  ],
  docType : [
    { text: 'CPL', id: 'cpl' },
    { text: 'Wire', id: 'wire' },
    { text: 'E & O', id: 'e_o' },
    { text: 'Fidelity/Crimes', id: 'crimes' },
    { text: 'State Licensing', id: 'state_licensing' },
    { text: 'Bank Ref', id: 'bank_ref' },
    { text: 'CPL Validation', id: 'cpl_validation' }
  ],
  emailType : [
    { text: 'Certification going to Lender Requestor', id: 1 },
    { text: 'Requesting and correcting documents', id: 2 },
    { text: 'Misc. Email', id: 3 },
    { text: 'Request Documents From Closing Agent', id: 4 }

  ],

  docStatus: {
      rej : 'rejected',
      req : 'request',
      rec : 'received',
      app : 'approved',
      e_o_insurance : 'E & O Insurance',
      fidelity_bond : 'Fidelity Bond/Crimes Policy',
      wells : 'Wells/Wachovia',
      state_licensing : 'State Licensing',
      cpl_validation : 'CPL Validation',
      wire : 'Wire',
      bank_ref : 'Bank Ref Letter',
      cpl : 'CPL'
  },
  transactionStatus: [
    {
    id : 1,
    label: 'Serious (Omissions/Expired Docs)',
  },{
    id : 2,
    label: 'No Issues (Docs Valid)',
  },{
    id : 3,
    label: 'Minor (Misspelling/Typographical Errors)',
  },
  {
    id : 4,
    label: 'Critical (Fraudulent/Mismatched Docs)',
  }]




};
