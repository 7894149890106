
import { NgModule } from '@angular/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { HeaderComponent } from './pages/header/header.component';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from './material/material.module';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { Utility } from '../async/utility';
import { NewProfileComponent } from './pages/new-profile/new-profile.component';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ClientTreeviewComponent } from './pages//client-treeview/client-treeview.component';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewGroupMasterComponent } from './pages/new-group-master/new-group-master.component';
import { ToolTipComponent } from '../modules/client/info/tool-tip/tool-tip.component';
import { WireAccountComponent } from './pages/wire-account/wire-account.component';
import { ClosingAgentContactComponent } from './pages/closing-agent-contact/closing-agent-contact.component';

@NgModule({
  declarations: [HeaderComponent, PrivacyPolicyComponent, DisclaimerComponent, ProfileComponent, NewProfileComponent, ClientTreeviewComponent, NewGroupMasterComponent, ToolTipComponent, WireAccountComponent, ClosingAgentContactComponent],
  imports: [
    CommonModule,
    NgxSelectModule,
    SharedMaterialModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TreeViewModule,
    CheckBoxModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      extendedTimeOut: 10000,
      positionClass: 'toast-top-right'
    }),
  ],
  providers: [Utility, { provide: MAT_DIALOG_DATA, useValue: [] }],
  exports: [
    HeaderComponent, PrivacyPolicyComponent, DisclaimerComponent, ProfileComponent, NewProfileComponent, ClientTreeviewComponent, NewGroupMasterComponent, ToolTipComponent
  ],
  entryComponents: [ProfileComponent, NewProfileComponent, ClientTreeviewComponent, NewGroupMasterComponent, WireAccountComponent, ClosingAgentContactComponent]
})
export class SharedModule { }
