<h2 mat-dialog-title>{{title}}</h2>
<!--<form [formGroup]="addNewParent" novalidate (ngSubmit)="add()">-->
<mat-dialog-content class="mat-typography">
  <form [formGroup]="profileForm" novalidate (ngSubmit)="submitProfile()">
    <div class="row">


      <div class="col-lg-6 grp-name">
        <label class="required-field">Profile Name:</label>
        <input matInput placeholder="" type="text" autocomplete="off" formControlName="profileName" (keyup)="isProfileKeyUp($event.target.value)">
        <span class="valid-error profile-name" *ngIf="isProfileNameEmpty && submited">Profile name is required.</span>
      </div>


      <div class="col-lg-6 grp-name" *ngIf="!isProfileType">
        <label class="required-field">Profile Type:</label>
        <mat-radio-group aria-label="Select an option" formControlName="profiletype" (change)="profileTypeChange($event)" class="fa-radio">
          <mat-radio-button value="FS Admin">FS Staff</mat-radio-button>
          <mat-radio-button value="Client Admin">Client</mat-radio-button>
        </mat-radio-group>
      </div>


      <div class="col-lg-6 grp-name" *ngIf="!isFsAdminProfileSelect">
        <label class="required-field">Client Group Name:</label>
        
        <mat-select (selectionChange)="groupChangeValue($event)" [value]="selectedGroupId$ | async" formControlName="clientGroupName">
          <mat-option value="">--Select--</mat-option>
          <mat-option *ngFor="let row of groupList$ | async;let i = index" [value]="row['groupId']">
            {{row['groupName']}}
          </mat-option>
        </mat-select>
      </div>

      <div class="col-lg-12">
        <!--
    @FS Admin
    -->
        <div class="product-inner-div" *ngIf="isFsAdminProfileSelect">

          <div class="container-lg">
            <div class="row product-list-cls">
              <div class="col-lg-6 group-dev">
                <div class="row">
                  <h2 mat-dialog-title class="tab-h2-tag" lass="col-lg-12 col-sm-12">{{leftSidebarLabel}}</h2>
                  <div class="col-lg-12 col-sm-12 pb0">
                    <!--<span class="search-label-right">Group</span>-->
                    <input type="text" matInput placeholder="Search.." class="input-text-search" (keyup)="groupFilter($event.target.value)" />
                  </div>
                  <div *ngIf="true" class="col-lg-12 pb0">
                      <div class="valid-error" *ngIf="isGroupEmpty && submited">
                        Client Group is required.
                      </div>
                    <div class="group-list">
                      <div class="checkbox-bg checkbox-filed checkbox-m" *ngFor="let row of groupList$ | async;let i = index ">
                        <mat-checkbox (change)="onChangeGroup($event,row,i)" [checked]="row?.isChecked">{{row['groupName']}}</mat-checkbox>
                      </div>

                    </div>

                  </div>


                </div>
              </div>
              <div class="col-lg-6 group-dev">
                <div class="row">
                  <h2 mat-dialog-title class="tab-h2-tag tab-h2-tag-right">{{sideBarLabel}}</h2>
                  <div class="col-lg-12 col-sm-12 right-sidebar pb0">
                    <!--<span class="search-label-right">Group</span>-->
                    <input type="text" matInput placeholder="Search.." class="input-text-search" (keyup)="selectedGroupFilter($event.target.value)" />
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="new-group-right">
                      <div class="selectedgroup-list" *ngFor="let row of selectedGroupList;let i = index;">

                       <i class="fas fa-window-close icon-class fa-1x" (click)="removeGroup(row)"></i> <span class="selected-text">{{row['groupName']}}</span>

                      </div>
                    </div>
                  </div>


                  <div class="button-second-condition">

                    <button type="button" mat-flat-button color="primary" class="fs-button1" (click)="close({status: false})">
                      Cancel
                    </button>
                    <button mat-flat-button color="primary" class="fs-button1" *ngIf="!isViewProfile">
                      Save
                    </button>
                  </div>
                </div>
                </div>
              <hr />

             
            </div>
          </div>






        </div>









        <div class="product-inner-div" *ngIf="!isFsAdminProfileSelect">

          <div class="container-lg">
            <div class="row product-list-cls">
              <div class="col-lg-6 group-dev">
                <div class="row">
                  <h2 mat-dialog-title class="tab-h2-tag" lass="col-lg-12 col-sm-12">Select Company</h2>
                  <div class="col-lg-12 col-sm-12 pb0">
                    <!--<span class="search-label-right">Company</span>-->
                    <input type="text" matInput placeholder="Search Company.." class="input-text-search" (keyup)="companyFilter($event.target.value)"  />
                  </div>
                  <div *ngIf="false" class="col-lg-12 group-list">
                    <!--<div class="row">
                      <div class="col-lg-10 col-sm-9" *ngFor="let row of groupList$ | async;let i = index ">
                        <mat-checkbox (change)="onChangeGroup($event,row,i)" [checked]="row?.isChecked">{{row['groupName']}}</mat-checkbox>
                      </div>
                    </div>-->


                  </div>
                  <ng-container *ngIf="true">
                    <div class="col-lg-12 col-sm-12">
                      <div id="wrapper">
                        <div id='content'>
                          <div class="spinner-grow text-danger" role="status" *ngIf="(isSpeener$ | async)">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="div-txt-msg" *ngIf="!localData">No Record Found</div>
                          <div id='treeparent'>
                            <!-- specifies the template string for the TreeView component-->
                            <span class="valid-error" *ngIf="isCompanyEmpty && submited">Client Name is required.</span>
                            <ejs-treeview id="tree" [fields]="field" [cssClass]="cssClass" [showCheckBox]='false'>
                              <ng-template #nodeTemplate="" let-data="">
                                <div>

                                  <div class="ename">
                                    <ejs-checkbox label='{{data.clientName}}' (change)="changeCheckbox($event , data)" [checked]="data?.highlight"></ejs-checkbox>
                                  </div>
                                  <!--<div class="ejob">{{data.job}}</div>-->
                                </div>
                              </ng-template>
                            </ejs-treeview>
                          </div>

                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>
              <div class="col-lg-6 group-dev">
                <div class="row">
                  <h2 mat-dialog-title class="tab-h2-tag tab-h2-tag-right">List of Products</h2>
                  <div class="col-lg-12 col-sm-12 pb0">
                    <!--<span class="search-label-right">Company</span>-->
                    <input type="text" matInput placeholder="Search Product.." class="input-text-search" (keyup)="productFilter($event.target.value)" />
                  </div>
                  <div class="col-lg-7 valid-error" *ngIf="isProductEmpty && submited">
                    Product is required.
                  </div>
                  <div class="col-lg-12 pb0">
                    <div class="product-list-div checkbox-filed checkbox-m">
                      <div class="checkbox-filed" *ngFor="let row of productList; let i = index;">
                        <mat-checkbox (change)="onChangeProduct($event,row,i)" [checked]="row?.productHighlight" [disabled]="row?.productDisable">{{row['productName']}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-sm-12 bordr-top pb0">
                    <div class="add-btn">
                      <div class="valid-error" *ngIf="domainEmpty && submited">
                        Domain is required.
                      </div>
                      <input type="text" matInput placeholder="Domain..i.e fundingshield.com" class="col-input-lg-7" (keyup)="domainKeyUp($event)" id="domain_input" />

                      <button mat-flat-button color="primary" class="prm-alert-df" [disabled]="!isDomainValid" (click)="domainAdd()">
                        Add
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="new-group-group-width">
                      <div class="selectedgroup-list" *ngFor="let domain of selectedDomainArray; let  i = index;">

                        <div><i class="fas fa-window-close icon-class fa-1x" (click)="removeDomain(i)"></i> <span class="selected-text">{{domain}}</span></div>

                      </div>
                      <!--<div class="col-lg-12 col-sm-12">

          <div><i class="fas fa-window-close icon-class fa-1x"></i> <span class="selected-text">www.facebook.com</span></div>

        </div>-->
                    </div>
                  </div>

                </div>
              </div>
             
            </div>
          </div>






        </div>
      </div>
      <div class="col-lg-12 col-out-class" *ngIf="!isFsAdminProfileSelect">
        <button mat-flat-button color="primary" class="fs-button1" *ngIf="!isViewProfile">
          Save
        </button>

        <button type="button" mat-flat-button color="primary" class="fs-button1" (click)="close({status: false})">
          Cancel
        </button>

      </div>

    </div>
  </form>

</mat-dialog-content>

<button mat-button mat-dialog-close class="popup-close-btn"><i class="material-icons">close</i></button>
