<div id="wrapper">
  <div id='content' >
    <div id='treeparent'>
      <!-- specifies the template string for the TreeView component-->
      <ejs-treeview id="tree" [fields]="field" [cssClass]="cssClass" [showCheckBox]='false'>
        <ng-template #nodeTemplate="" let-data="">
          <div>
            
            <div class="ename">
              <ejs-checkbox label='{{data.name}}'  [checked]="true" (change)="changeCheckbox(data)"></ejs-checkbox>

              
            </div>
            <!--<div class="ejob">{{data.job}}</div>-->
          </div>
        </ng-template>
      </ejs-treeview>
    </div>
  </div>
</div>
