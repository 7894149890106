import { Injectable } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends CoreService {
    public API_ENDPOINT;
  public env;
  constructor(public http: HttpClient) {
    super(http);
    let self = this;
    if (localStorage.getItem("configData")) {
      this.env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = self.env.API_ENDPOINT;
    }
  }
  syncConfig(){

    let self = this;
    if (localStorage.getItem("configData")) {
      this.env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = self.env.API_ENDPOINT;
    }
  }


  getProfileList(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `Profile/GetProfileList?LoginUserRole=${reqObj['LoginUserRole']}&Status=${reqObj['status']}&LoggedInUser=${reqObj['LoggedInUser']}`;
    if (reqObj['GroupId'] != "" && reqObj['GroupId'] != null) {
      baseUrl = this.API_ENDPOINT + `Profile/GetProfileList?LoginUserRole=${reqObj['LoginUserRole']}&GroupId=${reqObj['GroupId']}&LoggedInUser=${reqObj['LoggedInUser']}`;
    }
    return this.get(baseUrl);
    //
  }


  //Profile Remove
  // >> Removed Profile..
  groupDeactive(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/DeactivateGroupById?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj);
  }

  DeactivateLender(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/DeactivateLender?LoggedInUser=${reqObj['LoggedInUser']}&AzureKey=${reqObj['AzureKey']}`, reqObj);
  }

  ReactivateLender(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/ReactivateLender?LoggedInUser=${reqObj['LoggedInUser']}&AzureKey=${reqObj['AzureKey']}`, reqObj);
  }



  //Profile Remove
  // >> Removed Profile..
  groupReactivate(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/ReactivateGroupById?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj);
  }

  //NOW
  getFiltersList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Filter/GetFilters?createdby=${reqObj['userKey']}`);
  }



  // >> Purpose : Update  Profile...
  // >> Required: Update new profile..
  UpdateProfile(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/UpdateProfile?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj);
  }

  // >> Purpose : Get Client List..
  // >> Required : Get Group list api.
  GetGroupList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetClientGroupList?LoggedInUser=${reqObj['LoggedInUser']}&status=${reqObj['status']}`)
  }

  /**
 * @method: getState
 * @return `List of State`
 */
  getState(): Observable<any> {
    //
    return this.get(this.API_ENDPOINT + `DataLoad/GetStateList?clientKey=1234`)
  }


  // >> Add New Client
  CreateNewClient(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewLender?LoggedInUser=${reqObj['LoggedInUser']}&AzureKey=${reqObj['AzureKey']}`, reqObj);
  }


  // Get Client List
  // /User/GetClientList
  GetClientList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetClientList?LoggedInUser=${reqObj['LoggedInUser']}&azureUserKey=${reqObj['azureUserKey']}&status=${reqObj['status']}`)
  }



  // Get Client By Client Id
  // /User/GetClienById
  GetClientDetailById(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetClientDetailById?LoggedInUser=${reqObj['LoggedInUser']}&ClientMapId=${reqObj['ClientMapId']}`)
  }


  // >> Update Client New ..
  UpdateClient(reqObj, GuardianLenderKey, SpcLenderKey) {
    return this.post(this.API_ENDPOINT + `User/UpdateLender?LoggedInUser=${reqObj['LoggedInUser']}&AzureKey=${reqObj['AzureKey']}&GuardianLenderKey=${GuardianLenderKey}&SpcLenderKey=${SpcLenderKey}`, reqObj);
  }



  // Company Name Already Exists.
  isCompanyNameExist(clientName, LoggedInUser, GuardianLenderKey) {
    return this.get(this.API_ENDPOINT + `User/IsClientExists?LoggedInUser=${LoggedInUser}&clientName=${clientName}&GuardianLenderKey=${GuardianLenderKey}`);
  }


  // Get Child Company
  // Company Name Already Exists.
  getChildClient(reqObj) {
    return this.get(this.API_ENDPOINT + `User/GetChildPreDetailById?LoggedInUser=${reqObj['LoggedInUser']}&ParentClientMapKey=${reqObj['ParentClientMapKey']}`);
  }



  // Add  parent
  addParent(reqObj, LoggedInUser): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewLender?LoggedInUser=${LoggedInUser}`, reqObj);
  }
  // Add Parent

  addChild(reqObj, LoggedInUser, azureId): Observable<any> {

    return this.post(this.API_ENDPOINT + `User/AddNewLender?LoggedInUser=${LoggedInUser}&AzureKey=${azureId}`, reqObj);
  }

  //

  editChild(reqObj, GuardianLenderKey = null, SpcLenderKey = null, LoggedInUser): Observable<any> {
    let url = "";
    if (GuardianLenderKey && GuardianLenderKey != null && SpcLenderKey && SpcLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?GuardianLenderKey=${GuardianLenderKey}&SpcLenderKey=${SpcLenderKey}&LoggedInUser=${LoggedInUser}`;
    } else if (SpcLenderKey && SpcLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?SpcLenderKey=${SpcLenderKey}&LoggedInUser=${LoggedInUser}`;
    } else if (GuardianLenderKey && GuardianLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?GuardianLenderKey=${GuardianLenderKey}&LoggedInUser=${LoggedInUser}`;
    }
    return this.post(url, reqObj);
  }

  //
  //

  editParent(reqObj, GuardianLenderKey = null, SpcLenderKey = null, LoggedInUser): Observable<any> {
    let url = "";
    if (GuardianLenderKey && GuardianLenderKey != null && SpcLenderKey && SpcLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?GuardianLenderKey=${GuardianLenderKey}&SpcLenderKey=${SpcLenderKey}&LoggedInUser=${LoggedInUser}`;
    } else if (SpcLenderKey && SpcLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?SpcLenderKey=${SpcLenderKey}&LoggedInUser=${LoggedInUser}`;
    } else if (GuardianLenderKey && GuardianLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?GuardianLenderKey=${GuardianLenderKey}&LoggedInUser=${LoggedInUser}`;
    }
    return this.post(url, reqObj);
  }




  // >> /Profile/GetProfileDeactivateValidation
  GetProfileDeactivateValidation(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Profile/GetProfileDeactivateValidation?LoggedInUser=${reqObj['LoggedInUser']}&ProfileId=${reqObj['ProfileId']}`)
  }

  // >> ProfileActiveOperation
  ProfileActiveOperation(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `Profile/ActivateDeactivateProfile?LoggedInUser=${reqObj['LoggedInUser']}&ProfileId=${reqObj['ProfileId']}&operation=${reqObj['operation']}`, reqObj);
  }

  addUnderwriter(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewUnderwriter?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj['underwriterObj']);
  }
  GetUnderwriter(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetUnderwriter?LoggedInUser=${reqObj['LoggedInUser']}&Status=${reqObj['Status']}&CompanyId=${reqObj['CompanyId']}`);
  }
  //GetExistingOrderList(reqObj): Observable<any> {
  //  return this.post(this.API_ENDPOINT + `Transaction/GetExistingOrderList`, reqObj);
  //}

  GetAllClosingAgent(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/GetClosingAgentList`, reqObj);
  }

  AddClosingAgent(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewClosingAgent`, reqObj);
   }

  UpdateUnderwriter(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/UpdateUnderwriter?LoggedInUser=${reqObj['LoggedInUser']}&Status=${reqObj['Status']}`, reqObj['underwriterObj']);
  }


  GetWireAccountByClosingAgent(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetTitleWiredInfo?titleKey=${reqObj['titleKey']}&clientKey=${reqObj['clientKey']}&companyKey=${reqObj['companyKey']}&userKey=${reqObj['userKey']}`);
  }

  AddWireAccountByClosingAgent(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddWireAccountByClosingAgent`,reqObj);
  }


  ActivateDeactivateClosingAgent(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/ActivateDeactivateClosingAgent?agentKey=${reqObj['agentKey']}&status=${reqObj['Status']}&loggedInUserId=${reqObj['LoggedInUser']}`);
  }

  ActivateDeactivateWireAccount(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/ActivateDeactivateWireAccount?accountKey=${reqObj['wireObj']['accountKey']}&contactKey=${reqObj['wireObj']['contactKey']}&status=${reqObj['Status']}`);
  }

  GetClosingAgentContacts(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/GetClosingAgentContacts?CompanyID=${reqObj['CompanyID']}&userKey=${reqObj['userKey']}`);
  }

  GetClosingAgentContactsByContactId(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/GetClosingAgentContactsByContactId?ContactID=${reqObj['ContactID']}&userKey=${reqObj['userKey']}`);
  }

  AddClosingAgentContacts(userKey,reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/AddClosingAgentContacts?userKey=${userKey}`, reqObj);
  }

  UpdateClosingAgentContact(userKey, reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/UpdateContact?LoggedInUser=${userKey}`, reqObj);
  }

  DeleteContact(userKey, reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/DeleteContact?LoggedInUser=${userKey}`, reqObj);
  }

  ReActivateContact(userKey, reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/ReActivateContact?LoggedInUser=${userKey}`, reqObj);
  }






}
