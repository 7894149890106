import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import {Validations } from '../../constants/validations';
@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.css']
})
export class ForgotUsernameComponent implements OnInit {
  validMsg = Validations;
  forgotusernameform: FormGroup;
  submitted = false;
  error = '';
  constructor(fb: FormBuilder, private shareApi: SharedService) {
    this.forgotusernameform = fb.group({
      email: ["", Validators.required]
    });
  }

  ngOnInit() {
  }
  onSubmit() {
    if (this.forgotusernameform.valid) {
      console.log({ ...this.forgotusernameform.value });
    }
    this.shareApi.forgetUsername({ ...this.forgotusernameform.value }).subscribe((res) => {

    },
      (err) => {
        console.error(err)
      });

  }

}