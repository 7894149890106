import { Injectable } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UsersService extends CoreService {
    public API_ENDPOINT;
    public stateList$: Observable<any>;
    public userUpdate$: Observable<any> = of(null);
    public clientUpdate: Observable<any>;
    public clientList$: Observable<any>;
    public clientRole$: Observable<any>;
    public env;
  constructor(public http: HttpClient) {
    super(http);
    let self = this;
    if (localStorage.getItem("configData")) {
      this.env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = self.env.API_ENDPOINT;
    }
  }
  syncConfig(){
    let self = this;
    if (localStorage.getItem("configData")) {
      this.env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = self.env.API_ENDPOINT;
    }
  }

   /**
    * @method: getState
    * @return `List of State`
    */
   getState(): Observable<any>{
    //
     return this.get(this.API_ENDPOINT +`DataLoad/GetStateList?clientKey=1234`)
   }

  /**
*  @service `Get  all userdetaillist`
*  @parmas : added `Obj `
*  `Namespace` : @getUserDetailList
*/
  getUserDetail(reqObj, LoggedInUser) {
    return this.get(this.API_ENDPOINT + `User/GetUserDetailList?page=${reqObj['page']}&size=${reqObj['size']}&LoggedInUser=${LoggedInUser}`);
  }
  /**
*  @service `Get  all getUserDetailByUserId`
*  @parmas : added `Obj `
*  `Namespace` : @getUserDetailList
*/
  getUserDetailByUserId(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetUserDetailListByUserId?clientKey=${reqObj["clientKey"]}&userKey=${reqObj["userKey"]}`);
  }
  /**
*  @service `Get  all company name`
*  @parmas : added `Obj `
*  `Namespace` : @getUserDetailList
*/
  getCompanyName(LoggedInUser): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetCompanyName?LoggedInUser=${LoggedInUser}`);
  }
  /**
*  @service `Get  all role name`
*  @parmas : added `Obj `
*  `Namespace` : @getRoleName
*/
  getRoleName(LoggedInUser, LoggedInRole ): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetRoleName?LoggedInUser=${LoggedInUser}&LoggedInRole=${LoggedInRole }`);
  }
  /**
*  @service `Get  all client company name`
*  @parmas : added `Obj `
*  `Namespace` : @getClientCompanyName
*/
  getClientCompanyName(LoggedInUser): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetLenderCompanies?LoggedInUser=${LoggedInUser}`);
  }


  fetchAPISubscriptionKey(reqObject): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/subscriptionKey?azure_user_id=${reqObject['azure_user_id']}&LoginUserRole=${reqObject['LoginUserRole']}&LoggedInUser=${reqObject['LoggedInUser']}`);
  }

  regenSubKey(reqObject): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/RegenrateSubscriptionKey?Subscription_id=${reqObject['subscriptionId']}&LoggedInUser=${reqObject['LoggedInUser']}&Type=${reqObject['Type']}&selected_User_azureKey=${reqObject['selected_User_azureKey']}&productName=${reqObject['productName']}`);
  }

  getCompanyNameList(): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetCompanyName`);
  }


  addUser(reqObj, LoggedInUser ): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewUser?LoggedInUser=${LoggedInUser}`, reqObj);
  }





 // Add  parent
  addParent(reqObj, LoggedInUser): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewLender?LoggedInUser=${LoggedInUser}`, reqObj);
  }
  // Add Parent

  addChild(reqObj, parentKey, LoggedInUser): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewLender?parentKey=${parentKey}&LoggedInUser=${LoggedInUser}`, reqObj);
  }

  //

  editChild(reqObj, GuardianLenderKey = null, SpcLenderKey = null, LoggedInUser): Observable<any> {
    let url = "";
    if (GuardianLenderKey && GuardianLenderKey != null && SpcLenderKey && SpcLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?GuardianLenderKey=${GuardianLenderKey}&SpcLenderKey=${SpcLenderKey}&LoggedInUser=${LoggedInUser}`;
    } else if (SpcLenderKey && SpcLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?SpcLenderKey=${SpcLenderKey}&LoggedInUser=${LoggedInUser}`;
    } else if (GuardianLenderKey && GuardianLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?GuardianLenderKey=${GuardianLenderKey}&LoggedInUser=${LoggedInUser}`;
    }
    return this.post(url, reqObj);
  }

  //
    //

  editParent(reqObj, GuardianLenderKey = null, SpcLenderKey = null, LoggedInUser): Observable<any> {
    let url = "";
    if (GuardianLenderKey && GuardianLenderKey != null && SpcLenderKey && SpcLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?GuardianLenderKey=${GuardianLenderKey}&SpcLenderKey=${SpcLenderKey}&LoggedInUser=${LoggedInUser}`;
    } else if (SpcLenderKey && SpcLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?SpcLenderKey=${SpcLenderKey}&LoggedInUser=${LoggedInUser}`;
    } else if (GuardianLenderKey && GuardianLenderKey != null) {
      url = this.API_ENDPOINT + `User/UpdateLender?GuardianLenderKey=${GuardianLenderKey}&LoggedInUser=${LoggedInUser}`;
    }
    return this.post(url, reqObj);
    }


    // Add SPC
  addSPC(GuardianLenderKey, LoggedInUser, parentKey = null): Observable<any> {
    let url = "";
    if (parentKey && parentKey != null) {
      url = `User/AddSpcLender?GuardianLenderKey=${GuardianLenderKey}&parentKey=${parentKey}&LoggedInUser=${LoggedInUser}`;
    } else {
      url = `User/AddSpcLender?GuardianLenderKey=${GuardianLenderKey}&LoggedInUser=${LoggedInUser}`;
    }
      return this.post(this.API_ENDPOINT + `${url}`, null);
    }

    // Add Guardian
  addGuardian(spcLenderKey, LoggedInUser,parentKey = null): Observable<any> {
    let url = "";
    if (parentKey && parentKey != null) {
      url = `User/AddGuardianLender?spcLenderKey=${spcLenderKey}&parentKey=${parentKey}&LoggedInUser=${LoggedInUser}`;
    } else {
      url = `User/AddGuardianLender?spcLenderKey=${spcLenderKey}&LoggedInUser=${LoggedInUser}`;
    }
     return this.post(this.API_ENDPOINT + `${url}`, null);
    }



    // Add SPC USER
  addSpcUser(reqData, AzureUserKey, LoggedInUser, GuardianUserKey): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddSpcUser?AzureUserKey=${AzureUserKey}&LoggedInUser=${LoggedInUser}&GuardianUserKey=${GuardianUserKey}`, reqData);
    }

    // Add Guardian USER
    addGuardianUser(reqData,AzureUserKey,LoggedInUser,SpcUserKey): Observable<any> {
      return this.post(this.API_ENDPOINT + `User/AddGuardianUser?SpcUserKey=${SpcUserKey}&AzureUserKey=${AzureUserKey}&LoggedInUser=${LoggedInUser}`, reqData);
    }

    //Update User


  updateUser(reqObj, LoggedInUser, AzureId): Observable<any> {
    let url = "";
    //if (GuardianUserKey != "" && GuardianUserKey != null && SpcUserKey != "" && SpcUserKey != null) {
    //  url = this.API_ENDPOINT + `User/UpdateUser?LoggedInUser=${LoggedInUser}&GuardianUserKey=${GuardianUserKey}&SpcUserKey=${SpcUserKey}`
    //} else if (SpcUserKey != "" && SpcUserKey != null) {
    //  url = this.API_ENDPOINT + `User/UpdateUser?LoggedInUser=${LoggedInUser}&SpcUserKey=${SpcUserKey}`
    //} else if (GuardianUserKey != "" && GuardianUserKey != null) {
    //  url = this.API_ENDPOINT + `User/UpdateUser?LoggedInUser=${LoggedInUser}&GuardianUserKey=${GuardianUserKey}`
    //} else {
    //  url = this.API_ENDPOINT + `User/UpdateUser?LoggedInUser=${LoggedInUser}&AzureUserKey=${GuardianUserKey}`
    //}
    url = this.API_ENDPOINT + `User/UpdateUserDetailById?LoggedInUser=${LoggedInUser}&userKey=${AzureId}`
    return this.post(url, reqObj);
  }


  // Get SPC

/**
*  @service `Get SPC`
*  @parmas : added `userKey `
*  `Namespace` : @getSpcUserById
*/
  getSpcUserById(userKey, LoggedInUser): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetSpcUserDetailByUserId?userKey=${userKey}&LoggedInUser=${LoggedInUser}`);
  }

  // GET Guardian

/**
*  @service `Get Guardian bY User Id`
*  @parmas : added `userKey `
*  `Namespace` : @getGuardianUserById
*/
  getGuardianUserById(userKey, LoggedInUser): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetGuardianUserDetailByUserId?userKey=${userKey}&LoggedInUser=${LoggedInUser}`);
  }


  // Email Exists..
  isUserEmailExist(emailAddress, LoggedInUser ) {
    return this.get(this.API_ENDPOINT + `User/IsUserEmailExists?LoggedInUser=${LoggedInUser}&emailAddress=${emailAddress}`);

  }


  // Company Name Already Exists.
  isCompanyNameExist(clientName, LoggedInUser, GuardianLenderKey) {
    return this.get(this.API_ENDPOINT + `User/IsClientExists?LoggedInUser=${LoggedInUser}&clientName=${clientName}&GuardianLenderKey=${GuardianLenderKey}`);
  }


  // Get Docs API
  getDocsByUsers(LoggedInUser,GuardianClientId,stateCode = null){
    let url ="";
    if(stateCode && stateCode!="" && stateCode!= null){
      url = this.API_ENDPOINT + `User/GetGuardianRequiredDocument?companyKey=${GuardianClientId}&state=${stateCode}&LoggedInUser=${LoggedInUser}`;
    } else{
      url = this.API_ENDPOINT + `User/GetGuardianRequiredDocument?companyKey=${GuardianClientId}&LoggedInUser=${LoggedInUser}`;
    }
    return this.get(url);
  }

  addDocsByUser(LoggedInUser,GuardianClientId,reqObj,stateCode = null){
    let url ="";
    if(stateCode && stateCode!="" && stateCode!= null){
      url = this.API_ENDPOINT + `User/UpdateGuardianRequiredDocument?CompanyKey=${GuardianClientId}&LoggedInUser=${LoggedInUser}&state=${stateCode}`;
    } else{
      url = this.API_ENDPOINT + `User/UpdateGuardianRequiredDocument?CompanyKey=${GuardianClientId}&LoggedInUser=${LoggedInUser}`;
    }
    return this.post(url,reqObj);
  }


  // Delete User Details
  deleteUserDetails(reqObject): Observable<any>{
    return this.delete(this.API_ENDPOINT + `User/DeleteUserDetail?LoggedInUser=${reqObject['LoggedInUser']}&companyName=${reqObject['companyName']}&azureUserKey=${reqObject['azureUserKey']}&GuardianUser=${reqObject['GuardianUser']}&SpcUser=${reqObject['SpcUser']}`);
  }

  validationClientRelations(clientName, LoggedInUser){
    return this.get(this.API_ENDPOINT + `User/ValidateClientRelation?LoggedInUser=${LoggedInUser}&clientName=${clientName}`);
  }

  getGuardianParentList(LoggedInUser): Observable<any>{
       return this.get(this.API_ENDPOINT + `User/GetGuardianParentCompany?LoggedInUser=${LoggedInUser}`);
  }

  resetPassword(reqObject): Observable<any>{
       return this.get(this.API_ENDPOINT + `User/GetGuardianParentCompany?LoggedInUser=${reqObject['LoggedInUser']}`);
  }

  tempPasswordGenerated(reqObject) {
    return this.get(this.API_ENDPOINT + `User/GenerateTempPassword?userKey=${reqObject['userKey']}&azureUserId=${reqObject['azureUserId']}&email=${reqObject['email']}&loggedInUserId=${reqObject['loggedInUserId']}&LoginUrl=${reqObject['LoginUrl']}&FirstName=${reqObject['FirstName']}&LastName=${reqObject['LastName']}`);
  }




  // Get All Company
  // >> ALL USERS
  GetAllUser(reqObject): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetUserDetailList?LoggedInUser=${reqObject['LoggedInUser']}&azureUserKey=${reqObject['azureUserKey']}&LoginUserRole=${reqObject['LoginUserRole']}&Active_status=${reqObject['Active_status']}`);
  }

  // GetUser
  // >>GetUser
  GetUser(reqObject): Observable<any> {
    return this.get(this.API_ENDPOINT + `/User/GetUser?loggedInUserId=${reqObject['loggedInUserId']}&azureUserId=${reqObject['azureUserId']}`);
  }

  // >> Purpose : Get Client List..
  // >> Required : Get Group list api.
  GetGroupList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetClientGroupList?LoggedInUser=${reqObj['LoggedInUser']}&status=${reqObj['status']}`)
  }
  // Get Domain by group by id
  GetDomainByGroupId(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetDomainByGroupId?LoggedInUser=${reqObj['LoggedInUser']}&groupKey=${reqObj['GroupKey']}`);
  }
  // >> Purpose : Get Comapny List..
  // >> Required : Get Company list api.
  GetCompanyList(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `User/GetAllParentCompany?LoggedInUser=${reqObj['LoggedInUser']}`;
    if (reqObj['GroupKey'] != "") {
      baseUrl += `&GroupKey=${reqObj['GroupKey']}`;
    }
    return this.get(baseUrl)
  }
  // >> Purpose : Get Comapny List..
  // >> Required : Get Company list api.
  GetProfileNameById(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `Profile/GetProfileNameById?LoggedInUser=${reqObj['LoggedInUser']}&LoginUserRole=${reqObj['LoginUserRole']}&rolename=${reqObj['rolename']}`;
    if (reqObj['GroupId'] && reqObj['GroupId'] != "") {
      baseUrl += `&GroupId=${reqObj['GroupId']}`;
    }
    return this.get(baseUrl)
  }

  //>> Get User By Id
  getUserById(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetUserId?LoggedInUser=${reqObj['LoggedInUser']}&userKey=${reqObj['userKey']}`)
  }

  GetUserDetail(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetGuardianUserDetailByUserId?LoggedInUser=${reqObj['LoggedInUser']}&userKey=${reqObj['userKey']}`)
  }

  GetUserActivityLogs(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetUserActivityLogs?LoggedInUser=${reqObj['LoggedInUser']}&EntityId=${reqObj['EntityId']}&EntityName=${reqObj['EntityName']}`)
  }

}
