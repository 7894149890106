import { Injectable } from '@angular/core';
import * as Msal from '../../assets/lib/msal';
import { environment } from '../../environments/environment';
import { USER } from 'src/app/constants/const';
import jwt_decode from "jwt-decode";
import { LicenseManager } from 'ag-grid-enterprise';
import { SharedService } from './shared.service';
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute } from '@angular/router';
@Injectable()
export class MsalService {
  public AD_URL;
  public B2CTodoAccessTokenKey = "b2c.access.token";
  public accessToken;
  public tenantConfig;
  public authority;
  public clientApplication;
  public env;

  constructor(public sharedService: SharedService,
    private cookieService: CookieService,
    private router: Router) {
    //  setTimeout(() => {
    this.configAD();
    // }, 1000)

  }


  public configAD() {
    let self = this;

    self.sharedService.setConfigApi().subscribe(result => {
      if (result) {
        self.env = result;
        localStorage.setItem("configData", JSON.stringify(result));
        self.AD_URL = result.MSAL_REDIRECT_URL;
        self.AD_URL = result.MSAL_REDIRECT_URL;
        LicenseManager.setLicenseKey(result.AG_GRID_KEY);
        let scopeArray = result.B2C_SCOPE.split(",");
        scopeArray[1] = scopeArray[1].trim()
        self.tenantConfig = {
          tenant: result.TENANT_KEY,
          clientID: result.CLIENT_KEY,
          signInPolicy: result.SIGNIN_POLICY,
          signUpPolicy: result.SIGNUP_POLICY,
          editProfilePolicy: result.EDIT_POLICY,
          resetPasswordPolicy: result.RESET_PWD_POLICY,
          redirectUri: self.AD_URL,
          b2cScopes: scopeArray
        };

        // Configure the authority for Azure AD B2C
        self.authority = self.env.MICROSOFT_LOGIN_URL + self.tenantConfig.tenant + "/" + self.tenantConfig.signInPolicy + "/" + self.tenantConfig.redirectUri;
        // Default Constructer Called...
        self.msalObjectCreate();
      }
    }, error => {
      //  console.log(error)
    });
  }
  msalObjectCreate() {
    let self = this;
    /*
    * B2C SignIn SignUp Policy Configuration
    */
    self.clientApplication = new Msal.UserAgentApplication(
      self.tenantConfig.clientID, self.authority,
      function (errorDesc: any, token: any, error: any, tokenType: any) {
      },
      {
        redirectUri: self.AD_URL,
        navigateToLoginRequestUrl: false,
        state: '?user-type',
        cacheLocation: 'localStorage'
      }
    );
  }
  public login(): void {
    // this.clientApplication.authority = this.env.MICROSOFT_LOGIN_URL + this.tenantConfig.tenant + "/" + this.tenantConfig.signInPolicy + "/" + this.tenantConfig.redirectUri;
    // this.authenticate();
  }

  public editProfile(): void {
    // this.clientApplication.authority = this.env.MICROSOFT_LOGIN_URL + this.tenantConfig.tenant + "/" + this.tenantConfig.editProfilePolicy+"/"+ this.tenantConfig.redirectUri;
    // this.authenticate();
  }

  public resetPassword(): void {
    // this.clientApplication.authority = this.env.MICROSOFT_LOGIN_URL + this.tenantConfig.tenant + "/" + this.tenantConfig.resetPasswordPolicy + "/" + this.tenantConfig.redirectUri;
    // this.authenticate();
  }

  public authenticate(): void {
    // var _this = this;
    // this.clientApplication.loginRedirect(this.tenantConfig.b2cScopes);

  }

  saveAccessTokenToCache(accessToken: string): void {
    // this.accessToken = accessToken;
    // localStorage.setItem('token', this.accessToken);
    // localStorage.setItem('currentUser', JSON.stringify(this.clientApplication.getUser()))
    //   sessionStorage.setItem(this.B2CTodoAccessTokenKey, accessToken);
  };

  logout(): void {
    if (window.location.pathname != '/login') {
      this.cookieService.deleteAll('/');
      localStorage.removeItem("userKey");
      localStorage.removeItem("ng2Idle.main.idling");
      localStorage.removeItem("ng2Idle.main.expiry");
      localStorage.removeItem("userKey_b2c");
      localStorage.removeItem("userType");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetailsLocal");
      this.router.navigate(["/login"]);
    }
  };

  isLoggedIn(): boolean {
    const token = this.cookieService.get(USER);
    if (token && token != '') {
      const decoded: any = jwt_decode(token);
      if (decoded != null && decoded.user_id) {
        return true;
      }
      // else {
      //   this.logout();
      //   return false;
      // }
    }
    // else {
    //   this.logout();
    //   return false;
    // }
    return false;
  };

  getUserEmail(): string {
    return this.getUser().email;
  }

  getUser() {
    var cookieResponce = this.cookieService.get(USER);
    if (!cookieResponce || cookieResponce == '') {
      // window.location.href = "/login";

      if(window.location.pathname != '/login'){
        this.cookieService.deleteAll('/');
        localStorage.clear();
        window.location.href = '/login';
      }
      return;
    }
    const decodedUser: any = jwt_decode(cookieResponce);
    return decodedUser;
    // return this.clientApplication.getUser()
  }

  getAccessToken() {
    // return this.accessToken;
  }

  getAccessData() {
    // var _this = this;
    // setTimeout(() =>{
    //     _this.clientApplication.acquireTokenSilent(_this.tenantConfig.b2cScopes).then(
    //   function (accessToken: any) {
    //     _this.saveAccessTokenToCache(accessToken);
    //   }, function (error: any) {
    //     _this.clientApplication.acquireTokenPopup(_this.tenantConfig.b2cScopes).then(
    //       function (accessToken: any) {
    //         _this.saveAccessTokenToCache(accessToken);
    //       }, function (error: any) {
    //         console.log("error: ", error);
    //       });
    //   })
    //   },500);

  }
}





