<h2 mat-dialog-title>{{title}}</h2>
<!--<form [formGroup]="addNewParent" novalidate (ngSubmit)="add()">-->
<mat-dialog-content class="mat-typography" >
  <div class="row">






    <div class="col-lg-8 grp-name">
      <label class="required-field">Group Name:</label>
      <input matInput type="text" autocomplete="off" (keyup)="groupNameKeyUp($event)" [value]="groupNameValue">
      <span class="valid-error group-name" *ngIf="isGroupEmpty && submited">Group name is required.</span>
      
      
    </div>

    <div class="col-lg-12">
      <div class="product-inner-div">

        <div class="container-lg">
          <div class="row product-list-cls">
            <div class="col-lg-6 group-dev group-dev-next">
              <div class="row">
                <h2 mat-dialog-title class="tab-h2-tag" lass="col-lg-12 col-sm-12">Select Company</h2>
                <div class="col-lg-12 col-sm-12 pb0">
                  <!--<span class="search-label-right">Company</span>-->
                  <input type="text" placeholder="Search.." class="input-text-search" (keyup)="companyFilter($event.target.value)" />
                </div>
                <div class="col-lg-12 pb0">
                  <div class="valid-error" *ngIf="submited && isGroupListEmpty">
                    Company is required.
                  </div>
                  <div class="group-list">
                    <div class="checkbox-bg checkbox-filed checkbox-m" *ngFor="let row of groupList$ | async;let i = index ">
                      <ng-container *ngIf="row['clientName']!=''">
                        <mat-checkbox (change)="onChangeGroup($event,row,i)" [checked]="row?.isChecked">{{row['clientName']}}</mat-checkbox>
                      </ng-container>
                    </div>

                    <div class="checkbox-bg checkbox-filed checkbox-m noRecordFound" *ngIf="(groupList$ | async).length == 0">
                      No records found.
                    </div>
                    <!--<div class="checkbox-bg checkbox-filed checkbox-m noRecordFound" *ngIf="companyListEmpty">
                      No records found.
                    </div>-->

                  </div>

                </div>

                <div class="col-lg-5"></div>
                <div class="col-lg-12 col-lg-new-dev list-ground-div">

                  <div class="row">
                    <div class="col-lg-12 product-list pb0">
                      <label>List of Products</label>
                    </div>
                    <div class=" col-lg-12 pb0">
                      <div class="valid-error" *ngIf="submited && isProductEmpty">
                        Product is required.
                      </div>
                      <div class="product-list product-list-1">
                        <div class="checkbox-bg checkbox-filed checkbox-m" *ngFor="let row of productList$ | async; let i = index;">
                          <mat-checkbox (change)="onChangeProduct($event,row,i)" [checked]="row?.productHighlight">{{row['productName']}}</mat-checkbox>
                        </div>
                        <div class="checkbox-bg checkbox-filed checkbox-m noRecordFound" *ngIf="(productList$ | async).length == 0">
                          No records found.
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>

            <div class="col-lg-6 group-dev">
              <div class="row">
                <h2 mat-dialog-title class="tab-h2-tag tab-h2-tag-right">All Selected Company</h2>
                <div class="col-lg-12 col-sm-12 pb0 right-sidebar">
                  <!--<span class="search-label-right">Company</span>-->
                  <input type="text" placeholder="Search.." class="input-text-search" (keyup)="selectedCompanyFilter($event.target.value)" />
                </div>
                <div class="col-lg-12 col-sm-12 pb3">
                  <div class="new-group-right">
                    <div class="selectedgroup-list" *ngFor="let row of selectedGroupList;let i = index;">

                      <div><i class="fas fa-window-close icon-class fa-1x" (click)="removeGroup(row)"></i> <span class="selected-text">{{row['clientName']}}</span></div>

                    </div>

                    <div class="checkbox-bg checkbox-filed checkbox-m noRecordFound" *ngIf="selectedGroupList.length == 0">
                      No records found.
                    </div>

                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-12 bordr-top pb0">
                  <div class="add-btn">
                    <div class="valid-error" *ngIf="domainEmpty && submited">
                      Domain is required.
                    </div>
                    <input type="text" matInput placeholder="Domain..i.e fundingshield.com" class="col-input-lg-7" (keyup)="domainKeyUp($event)" id="domain_input" />

                    <button mat-flat-button color="primary" class="prm-alert-df" [disabled]="!isDomainValid" (click)="domainAdd()">
                      Add
                    </button>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <div class="new-group-group-width">
                    <div class="selectedgroup-list" *ngFor="let domain of selectedDomainArray; let  i = index;">

                      <div><i class="fas fa-window-close icon-class fa-1x" (click)="removeDomain(i)"></i>
                      <span class="selected-text" title="{{domain}}">{{domain}}</span></div>

                    </div>
                    <!--<div class="col-lg-12 col-sm-12">

          <div><i class="fas fa-window-close icon-class fa-1x"></i> <span class="selected-text">www.facebook.com</span></div>

        </div>-->
                  </div>
                </div>

              </div>
            </div>
            

          </div>
        </div>






      </div>


    </div>
    <div class="col-lg-12 col-out-class" *ngIf="!isView">
      <button mat-flat-button color="primary" class="fs-button1" (click)="submitProfile()">
        Save
      </button>
      <button type="button" mat-flat-button mat-dialog-close color="primary" class="fs-button1" (click)="close({ status: false })">
        Cancel
      </button>
    </div>

    <div class="col-lg-12 col-out-class" *ngIf="isView">
      <button type="button" mat-flat-button mat-dialog-close color="primary" class="fs-button1" (click)="close({ status: false })">
        Close
      </button>
    </div>
  </div>
  
</mat-dialog-content>
<!--</form>-->
<button mat-button mat-dialog-close class="popup-close-btn"><i class="material-icons">close</i></button>
