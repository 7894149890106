import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './core/not-found/not-found.component';

/* tslint:disable */
//
const appRoutes: Routes = [


	{
		path: 'users',
		//canLoad: [ClientGuard],
		loadChildren: () => import('./modules/users/users.module').then(mod => mod.UsersModule),
		data: { preload: false }
  },
  {
    path: 'client',
    //canLoad: [ClientGuard],
    loadChildren: () => import('./modules/client/client.module').then(mod => mod.ClientModule),
    data: { preload: false }
  },
	{
		path: '',
		loadChildren: () => import('./core/core.module').then(mod => mod.CoreModule),
		data: { preload: false }
	},

	{ path: '**', component: NotFoundComponent }
];



@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
