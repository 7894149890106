import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './jwt.interceptor'

// import { LoaderInterceptor } from './loader.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { HttpsInterceptor } from './https.interceptor';
import { BaseUrlInterceptor } from './base_url.interceptor';
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true },
    
    
  ];