<h2 mat-dialog-title>{{mode}} Contact</h2>
<form style="padding:0 24px;" [formGroup]="closingAgentContactForm" novalidate (ngSubmit)="addContact()">
  <mat-dialog-content class="mat-typography addNewcl">

    <div class="row">

      <div class="col-12">
        <label>First Name:</label>
        <input placeholder="" type="text" formControlName="firstName">
      </div>

      <div class="col-12">
        <label>Last Name:</label>
        <input placeholder="" type="text" formControlName="lastName">
      </div>

      <div class="col-12">

        <label class="required-field">Email:</label>
        <input matInput placeholder="" type="email" formControlName="email">
        <div *ngIf="contactForm['email']?.touched && contactForm['email'].errors">
          <mat-error class="error-cus" *ngIf="contactForm['email'].errors?.required">
            {{validMessage.Message.emailFieldRequird}}
          </mat-error>
          <mat-error class="error-cus" *ngIf="contactForm['email'].errors?.email || contactForm['email'].errors?.pattern">
            Please enter valid email address.
          </mat-error>

        </div>
      </div>

      <div class="col-12">
        <label class="required-field">Phone:</label>
        <input matInput placeholder="" (keyup)="chkPhone($event)" type="tel" minLength="10" formControlName="phone"
               mask='(000) 000-0000'>
        <div *ngIf="contactForm['phone']?.touched && contactForm['phone'].errors">
          <mat-error class="error-cus" *ngIf="contactForm['phone'].errors?.required">
            {{validMessage.Message.phoneFieldRequired}}.
          </mat-error>
          <mat-error class="error-cus" *ngIf="contactForm['phone'].dirty && contactForm['phone'].errors?.minlength">
            Phone must be of 10 digits.
          </mat-error>
        </div>
      </div>


    </div>


  

    <div class="row mt-3">
      <div class="col-lg-12 text-center">
        <div>
          <button mat-flat-button color="primary" class="vertical-center">
            {{mode}}
          </button>
        </div>
      </div>
    </div>

  </mat-dialog-content>
</form>
<button mat-button mat-dialog-close class="popup-close-btn"><i class="material-icons">close</i></button>
