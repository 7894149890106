<div class="container-fluid profiles-bg">
  <div class="row profile-row">
    <div class="left-profile">
      <div class="image-container"><img [src]="img$ | async"> </div>
      <div class="name">{{userName$ | async}}</div>
      <div class="designation">{{roleType$ | async}}</div>
      <br/>
      <div class="name" *ngIf="!hideAvtarLink"><a class="" href="javascript:void(0)"
   (click)="removeImage()">Use Default Avatar</a></div>
    </div>
    <div class="col-lg-12 tab-container-bg">
      <div class="right-profile">

        <mat-tab-group class="horizontal-tab" [(selectedIndex)]="selectedIndex">

          <mat-tab label="Update Profile">
            <form [formGroup]="profileForm" novalidate (ngSubmit)="updateProfile()">
              <div class="row ag-grid-profile">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-12 fs-filetype">
                      <label>Profile Picture:</label>
                      <div class="file-container">
                        <mat-form-field appearance="outline">
                          <ngx-mat-file-input placeholder="Basic Input" (change)="fileUpload($event)"
                                              (cancel)="cancel()" formControlName="profileImage">
                          </ngx-mat-file-input>
                          <span class="file-btn">Choose File</span>
                        </mat-form-field>
                        <mat-error *ngIf="changePasswordForm.controls['profileImage']?.errors?.required">
                          Please select a file
                        </mat-error>
                        <mat-error *ngIf="changePasswordForm.controls['profileImage']?.errors?.maxContentSize">
                          The total size must not exceed {{changePasswordForm.controls['profileImage']?.errors?.maxContentSize.maxSize | byteFormat}} ({{
changePasswordForm.controls['profileImage']?.errors?.maxContentSize.actualSize
                                                                                                | byteFormat
                          }}).
                        </mat-error>

                      </div>
                    </div>
                    <div class="col-lg-12">
                      <label class="required-field">First Name:</label>
                      <input matInput placeholder="" type="text" formControlName="displayName">
                    </div>
                    <div class="col-lg-12">
                      <label>Last Name:</label>
                      <input matInput placeholder="" type="text" formControlName="surname">
                    </div>
                    <div class="col-lg-12">
                      <label class="required-field">Email:</label>
                      <input matInput placeholder="" type="email" class="disable" formControlName="userPrincipalName" readonly>
                    </div>
                    <div class="col-lg-12">
                      <label>Title:</label>
                      <input matInput placeholder="" type="text" formControlName="jobTitle">
                    </div>
                    <div class="col-lg-12">

                      <label>Phone:</label>
                      <input matInput placeholder="" type="tel"
                             mask="(000) 0000-0000" formControlName="businessPhones">






                    </div>
                    <div class="col-lg-12">




                      <label>EXT:</label>
                      <input matInput placeholder="" type="tel"
                             mask="(000)" formControlName="extension">


                    </div>
                    <!--<div class="col-lg-12">
          <label>Extension:</label>
          <input matInput placeholder="" type="text" formControlName="extension">
  </div>-->
                    <!--<div class="col-lg-12">
          <label>Fax:</label>
          <input matInput placeholder="" type="text" formControlName="fax">
  </div>-->
                    <div class="col-lg-12">
                      <label>Mobile:</label>
                      <input matInput placeholder="" type="tel" formControlName="mobilePhone" mask="(000) 000-0000">
                    </div>
                    <!--<div class="col-lg-12">
          <label>Role:</label>
          <input matInput placeholder="" type="text"   readonly>
  </div>-->
                    <div class="col-lg-12">
                      <div class="profiles-right-btn update-profile">
                        <button mat-flat-button color="primary"
                                class="fs-button">
                          Update
                          Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </div>


                <!--<div class="col-lg-6 tree-view-profile" *ngIf="isClient">
                  <div class="company-list">Company Profile</div>
                  <div class="company-list-name">{{profileName}}</div>
                  <div class="company-list">Company List</div>
                  <ngx-treeview [config]="config"
                                [items]="items" *ngIf="!(isSpeener$ | async)">
                  </ngx-treeview>
                  <div class="spinner-grow text-danger" role="status" *ngIf="(isSpeener$ | async)">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>-->
              </div>
            </form>
          </mat-tab>
          <mat-tab label="Change Password">
            <form [formGroup]="changePasswordForm" novalidate (ngSubmit)="changePassword()">
              <div class="row">
                <!--<div class="col-lg-12">
                        <label>Old Password:</label>
                        <input matInput placeholder="" type="{{oldPassText}}" formControlName="oldPassword">
                        <mat-icon color="primary" class="show-password" (click)="oldPass()">
                                remove_red_eye</mat-icon>
                </div>-->
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-12">
                      <label class="required-field">Old Passowrd:</label>
                      <input matInput placeholder="" type="{{oldPassText}}" formControlName="oldPassword" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#[~$@()$!%*?&<>^+])[A-Za-z\d$#[~@()$!%*?&<>^+].{7,}">
                      <mat-icon color="primary" class="show-password" (click)="oldPass()">
                        remove_red_eye
                      </mat-icon>
                    </div>
                    <div class="col-lg-12">
                      <label class="required-field">New Passowrd:</label>
                      <input matInput placeholder="" type="{{newPassText}}" formControlName="newPassword" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#[~$@()$!%*?&<>^+])[A-Za-z\d$#[~@()$!%*?&<>^+].{7,}">
                      <mat-icon color="primary" class="show-password" (click)="newPass()">
                        remove_red_eye
                      </mat-icon>
                      <br />
                      <mat-error *ngIf="passwordLabel.errors">
                        <span *ngIf="passwordLabel.touched &&  passwordLabel.errors?.required" class="span-error">
                          Password field is required.
                        </span>
                        <span *ngIf="!passwordLabel.errors?.minlength && passwordLabel.errors?.pattern" class="span-error">
                          At least one uppercase letter, one lowercase letter, one number and one special character (Except: / \ : ; " ').
                        </span>
                        <span *ngIf="passwordLabel.errors?.minlength " class="span-error">
                          Password field  Minimum eight characters required.
                        </span>
                      </mat-error>
                    </div>
                    <div class="col-lg-12">
                      <label class="required-field">Confirm:</label>
                      <input matInput placeholder="" type="{{confirmPassText}}" formControlName="confirmPassword" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#[~$@()$!%*?&<>^+])[A-Za-z\d$#[~@()$!%*?&<>^+].{7,}">

                      <mat-icon color="primary" class="show-password" (click)="confirmPass()">
                        remove_red_eye
                      </mat-icon>
                      <mat-error *ngIf="confirmPasswordLabel.errors">
                        <span *ngIf="confirmPasswordLabel.touched && confirmPasswordLabel.errors?.required" class="span-error">
                          Confirm Password field is required.
                        </span>
                        <span *ngIf="!confirmPasswordLabel.errors?.minlength && confirmPasswordLabel.errors?.pattern" class="span-error">
                          At least one uppercase letter, one lowercase letter, one number and one special character (Except: / \ : ; " ').
                        </span>
                        <span *ngIf="confirmPasswordLabel.errors?.minlength " class="span-error">
                          Confirm Password field Minimum eight characters required.
                        </span>
                        <span *ngIf="confirmPasswordLabel.errors?.maxlength " class="span-error">
                          Confirm Password field Maximum 256 characters allowed.
                        </span>
                      </mat-error>
                      <mat-error class="confirm_password" *ngIf="!confirm() && !changePasswordForm.controls['confirmPassword']?.errors?.pattern">
                        Password and confirm password does not match.
                      </mat-error>
                    </div>
                    <div class="col-lg-12">
                      <div class="profiles-right-btn">
                        <button mat-flat-button color="primary"
                                class="fs-button" [disabled]="changePasswordForm.invalid">
                          Change
                          Password
                        </button>
                        <br />
                        <span style="color:darkblue">
                          Your Password must have at least one uppercase letter, one lowercase letter, one number, one special character (Except: / \ : ; " ') and at least 8 character.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-tab>
        </mat-tab-group>

      </div>
    </div>
  </div>
</div>
