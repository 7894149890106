<div class="container-fluid static-page">
    <div class="row">
        <div class="col-lg-12">
            <h4>PRIVACY POLICY</h4>
            <p>FundingShield, LLC. values privacy as one of the core principles of a free society. Ethical standards lead our drive to provide outstanding service to our customers, while respecting the privacy of individuals. We are a resource to provide public information and other proprietary data to businesses in a manner that balances personal privacy and public trust. FundingShield, LLC. provides data to empower lenders, employers and businesses to protect their assets and retain profitability.</p>
            <p>The information provided by FundingShield, LLC. is comprised of public records and publicly available data. This information is and shall only be acquired from governmental agencies, credit bureaus and reputable third party businesses. Reasonable steps shall be taken to ensure the accuracy of the information we provide. If an alleged inaccuracy occurs concerning information contained in our reports, every reasonable step will be taken to verify the truthfulness of the information. When contacted by an individual concerning an alleged inaccuracy, the individual will be informed of the procedure to submit a request for corrective action review and told where a request for correction may be made. </p>
            <p>FundingShield, LLC. shall provide access only to qualified businesses and licensed business professionals (subscribers) who have executed our Service Agreement and can demonstrate appropriate use for the information. </p>
            <p>Each subscriber must agree to abide by all local and federal laws in the acquisition and application of such information. FundingShield, LLC. may disclose personal information if required to do so by law or to comply with any legal process served upon FundingShield, LLC. All public record information and publicly available information shall be usable without restriction unless legally prohibited. </p>
            <p>FundingShield, LLC. shall maintain facilities and systems to protect information from unauthorized access or persons who may try to exceed their authorization. This may include physical and electronic security, signed Confidentiality Agreements and appropriate due diligence for compliance. </p>
            <p>FundingShield, LLC. is an advocate of proper balance between business needs and consumer rights and believes that the consumer has the following rights: </p>
            <ul>
                <li>The right to be treated with respect and fairness.</li>
                <li>The right to know what information has been provided about you.</li>
                <li>The right to challenge and correct inaccurate information.</li>
                <li>The right to expect the information is collected and stored legally, using sound practices of record keeping and information systems management.</li>
            </ul>
            <p>FundingShield, LLC. is committed to encouraging openness and trust in the electronic commerce of information. You should check the Privacy Policy of every website you visit to ensure that you are comfortable with their practices. We shall adhere to the highest ethical standards in providing outstanding service to our customers, while continuing to respect the privacy of individuals. </p>
            <p>FundingShield, LLC. supports the principles and practices of the Individual Reference Services Group [I.R.S.G.] and the Fair Credit Reporting Act [F.C.R.A.].</p>

            <h4>Encryption</h4>
            <p>When you send your personal financial information to us, it is protected by SSL (Secure Sockets Layer) Technology. SSL does not allow anyone to intercept and read your personal information. When you send your information to us, SSL encrypts it (translates the data into a string of gibberish characters). Only we can decode the encryption.</p>
           <h4>Firewall Protection</h4>
            <p>Your financial information is not kept on our Web server, but on a different computer that is separated from the Internet. Even in the unlikely event that our Web site is "hacked," your data will still remain securely out of reach. </p>
            
            <h4>Password Protection</h4>
            <p>To retrieve information about status or edit existing data, you are required to enter both a user name and password of which is proprietary to you only.</p>
            <h4>What about "cookies" and other tracking devices?</h4>
            <p>We use cookies to assist FundingShield employees and to enhance the performance of our Web site. (Cookies are small text files sent from the Web server to your computer.) The cookies this site creates do not contain any personal information nor do they contain account information. They merely allow the site to recognize that a page request comes from someone who has already logged on. </p>
            <p>Cookies may also be placed by third parties when you access their sites through linking from this Web site. We do not have access to these cookies or any information that these cookies may contain. Please contact the third-party site for more information on these cookies. Although we encourage third parties to adhere to appropriate privacy policies and standards, we are not responsible for the actions or policies of such parties.</p>
            <p>FundingShield, LLC. may share Web site usage information about visitors to the Web site with reputable advertising companies for the purpose of enhancing our online experience </p>
            <p>FundingShield reserves the rights to modify or update our Privacy Policy. When and if this occurs, we will post the changes on our website or contact our subscribers via e-mail. </p>

        </div>
    </div>
</div>
