import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Utility } from "./async/utility";
import { SharedService } from "./services/shared.service";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { MsalService } from "src/app/services/msal.service";
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "Funding Shield";
  idleState = "Not started.";
  timedOut = false;
  loggedInIdleTimeout = 0;
  constructor(
    private loader: NgxUiLoaderService,
    private utilService: Utility,
    public shareService: SharedService,
    private idle: Idle,
    public msalService: MsalService,
    private router: Router
  ) {

    var result: any = localStorage.getItem("configData");
    if (result) {
      result = JSON.parse(result);
      this.loggedInIdleTimeout = parseInt(result.LoggedInIdleTimeout);
    }
    else {
      this.loggedInIdleTimeout = 3600;
    }

    console.log("Idle Time  constructor " + Number(this.loggedInIdleTimeout));
    // sets an idle timeout
    idle.setIdle(Number(this.loggedInIdleTimeout));

    // sets a timeout period , after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => (this.idleState = "No longer idle."));
    idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      if (this.msalService.isLoggedIn()) {
        // window.location.href = '/login';
        if (window.location.pathname != '/login') {
          this.msalService.logout();
          window.location.href = '/login';
        }
      }
      console.log(this.idleState);
      this.resetIdleWatch();
    });
    idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
    idle.onTimeoutWarning.subscribe(
      (countdown) =>
        (this.idleState = "You will time out in " + countdown + " seconds!")
    );
    this.resetIdleWatch();
  }
  resetIdleWatch() {
    var result: any = localStorage.getItem("configData");
    if (result) {
      result = JSON.parse(result);
      this.loggedInIdleTimeout = parseInt(result.LoggedInIdleTimeout);
    }
    else {
      this.loggedInIdleTimeout = 3600;
    }
    this.idle.setIdle(Number(this.loggedInIdleTimeout));
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log("Idle Time  ngOnInit " + Number(this.loggedInIdleTimeout));
        if (this.msalService.isLoggedIn()) {
          this.resetIdleWatch();
        }
      }
    });

    this.utilService.loaderStatusGet().subscribe((status) => {
      if (status) {
        this.loader.start();
      } else {
        this.loader.stop();
      }
    });
  }
}
