import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CoreService {
  // Set Here Base Url...

  constructor(public http: HttpClient) {

  }


 /**
  * @method : jwt
  * @purpose:- Set Authoriztion as Token and also set as Authoriztion..
  */
  private jwt() {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    if (token) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': token
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }
  }

 /**
  * @method: post
  * @purpose: this is post type method is called
  * @param url
  * @param data
  */
  public post(url, data) {
    return this.http.post(url, data , this.jwt());
  }
  public postWithOptions(url, data, options) {
    return this.http.post(url, data , options);
  }


 /**
  * @method: put
  * @purpose: this is put type method is called
  * @param url
  * @param data
  */
 public put(url, data) {
  return this.http.put(url, data , this.jwt());
 }


  /**
  * @method: petch
  * @purpose: this is petch type method is called
  * @param url
  * @param data
  */
  public patch(url, data =null) {
    return this.http.patch(url, data, this.jwt());
  }


 /**
  * @method: get
  * @purpose: this is get type method is called
  * @param url
  * @param data
  */
  public get(url): Observable<any> {
  return this.http.get<any>(url, this.jwt());
  }

 /**
  * @method: delete
  * @purpose: this is get type method is called
  * @param url
  * @param data
  */
 public delete(url): Observable<any>  {
  return this.http.delete(url, this.jwt());
 }

}
