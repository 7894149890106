export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token?: string;
    mobile?: any;
    fax?: any;
    extension?: string;
    phone?: any;
    title?: string;
    profilePicture?: string;
}