<h4 class='text-center' style='color: #E21549'>Veritcal tabs with Material design lite</h4>
<div class="mdl-tabs vertical-mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
										  <div class="mdl-grid mdl-grid--no-spacing">
										    <div class="mdl-cell mdl-cell--2-col">
											      <div class="mdl-tabs__tab-bar">
											         <a href="#tab1-panel" class="mdl-tabs__tab is-active">
											      	     <span class="hollow-circle"></span>
											      	      One Punch Man
											         </a>
											         <a href="#tab2-panel" class="mdl-tabs__tab">
											      	      <span class="hollow-circle"></span>
											      	      One Peace
											          </a>
											          <a href="#tab3-panel" class="mdl-tabs__tab">
											      	      <span class="hollow-circle"></span>
											            	Spongebob
											          </a>
											     </div>
											   </div>
											   <div class="mdl-cell mdl-cell--10-col">
												      <div class="mdl-tabs__panel is-active" id="tab1-panel">
													         <img src='https://i0.wp.com/www.thebuttonsmashers.com/wp-content/uploads/2015/10/one_punch_man_short_animation__by_astralnovas-d89p2rx.gif' alt='One Punch Man' width='250'>
												      </div>
												      <div class="mdl-tabs__panel" id="tab2-panel">
                                <img src='https://media.giphy.com/media/bGmzbqDKcmvyU/giphy.gif' alt='Luffy the Pirate king' height='150'>
      												</div>
      												<div class="mdl-tabs__panel" id="tab3-panel">
													          <img src='https://media.giphy.com/media/yqMtk0TfjRa5W/giphy.gif' height='150' />
												      </div>
											    </div>
										  </div>
									</div>