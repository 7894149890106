
import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable, of } from 'rxjs';
import { User } from '../shared/interface/users.model';

import { LicenseManager } from 'ag-grid-enterprise';
@Injectable({
  providedIn: 'root'
})
export class SharedService extends CoreService {

  public API_ENDPOINT;
  public NODE_URL: string =
    "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");
  public config$: Observable<any>;



  constructor(public http: HttpClient) {
    super(http);
    let self = this;

    if (localStorage.getItem("configData")) {
      const configData = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = configData.API_ENDPOINT;
      LicenseManager.setLicenseKey(configData.AG_GRID_KEY);
    }
    if (window.location.host && window.location.host.indexOf('localhost') > -1) {
      this.NODE_URL = "assets/configs.json";
      // this.setConfiguaration();
    } else {
      this.NODE_URL =
      "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");
      //this.setConfiguaration();
    }
    if (localStorage.getItem("configData")) {
      let envFile = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = envFile.API_ENDPOINT;
    }
  }
  syncConfig() {
    let self = this;

    if (localStorage.getItem("configData")) {
      const configData = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = configData.API_ENDPOINT;
      LicenseManager.setLicenseKey(configData.AG_GRID_KEY);
    }
    if (window.location.host && window.location.host.indexOf('localhost') > -1) {
      this.NODE_URL = "assets/configs.json";
      // this.setConfiguaration();
    } else {
      this.NODE_URL =
      "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");
      //this.setConfiguaration();
    }
    if (localStorage.getItem("configData")) {
      let envFile = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = envFile.API_ENDPOINT;
    }
  }



  setConfigApi(): Observable<any> {
      if ((window.location.host && window.location.host.indexOf('localhost') > -1 ) || window.location.host.indexOf('192.168.0.30') > -1) {
              let host = 'localhost';
              if(window.location.host.indexOf('192.168.0.30') > -1){
                host = '192.168.0.30';
              }
              return of({
                "timestamp": "2020-02-17T07:01:07.365Z",
                "API_ENDPOINT": `https://${host}:44357/`,
                "B2C_SCOPE": "openid, profile",
                "CLIENT_KEY": "4cbb14bf-91f6-4eef-90a7-d5e27ad6bfdc",
                "MICROSOFT_LOGIN_URL": "https://login.microsoftonline.com/tfp/",
                "MSAL_REDIRECT_URL": `http://${host}:44318/user-type`,
                "RESET_PWD_POLICY": "B2C_1_PasswordReset",
                "SIGNIN_POLICY": "B2C_1_SignUpSignIn",
                "SIGNUP_POLICY": "B2C_1_SignIn",
                "EDIT_POLICY": "B2C_1_EditProfile",
                "TENANT_KEY": "aaf09b0a-e24c-4359-b3d4-7553a65a722d",
                "EndpointCode": "dcfa18b2-c330-4d5f-bdba-ea8ecc3302b6",
                "EndpointLogin": "https://localhost:5000",
                "PasswordExpiryWarningDaysLeft": "7",
                "EndpointEntity": "fs.admin.ui",
                "AG_GRID_KEY": "CompanyName=FundingShield LLC,LicensedApplication=Guardian,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-012692,ExpiryDate=21_January_2022_[v2]_MTY0MjcyMzIwMDAwMA==d2cb4beea513d51fb197ddcf1410c40b",
                "LoggedInIdleTimeout":900,
                "AuthCookieLifeSpanInSeconds" : 19200
              });
      }
      else {

      return this.get(this.NODE_URL);
    }

  }

  setConfiguaration() {
    //
    this.get(this.NODE_URL).subscribe(value => {
      if (value) {
        this.config$ = of(value);
        localStorage.setItem("configData", JSON.stringify(value));
        this.API_ENDPOINT = value["API_ENDPOINT"];
      }
    }, error => {
      console.error(error)
    })
  }
  /**
   * @method: login
   * @param reqData
   */
  public login(reqData: object): Observable<any> {
    return this.post(`${this.API_ENDPOINT}/Login`, reqData);

  }


  public isExists(url, reqObj): Observable<any> {
    return;
  }


  public isLogedin(): Observable<any> {
    return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : undefined;

  }


  //resetPassword(reqObj): Observable<any> {
  //  return this.get(this.API_ENDPOINT + `User/ResetPassword?loggedInUserId=` + reqObj['loggedInUserId'] + `&azureUserId=` + reqObj['azureUserId'] + `&password=` + reqObj['password']);
  //}


  resetPassword(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/ResetPassword`, reqObj);

  }




  /**
   * @method `Forget Username`
   */
  public forgetUsername(reqObj): Observable<any> {
    return this.post(`forget-username`, reqObj);
  }

  /**
   * @method `Forget Password`
   */
  public forgetPwd(reqObj): Observable<any> {
    return this.post(`forget-username`, reqObj);
  }


  /**
   * @method: getState
   * @return `List of State`
   */
  getState(): Observable<any> {
    //
    return this.get(this.API_ENDPOINT + `DataLoad/GetStateList?clientKey=1234`)
  }


  /**
   * @method: getProfile
   */
  getProfile(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetUser?loggedInUserId=${reqObj['userKey']}&azureUserId=${reqObj['azureUserKey']}`)
  }
  /**
   * @method : `updateProfile`
   * @purpose : for this `required for update profile.`
   */
  updateProfile(userKey, azureUserKey, reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/ProfileUpdate?loggedInUserId=` + userKey + `&azureUserId=` + azureUserKey, reqObj);
  }

  /**
   * @method : `changePasword`
   * @purpose : for this `required for change password.`
   */
  changePasword(reqObj, user_id, client_id): Observable<any> {
    return this.post(`change-password?user_id=1112d&client_id=4545`, reqObj);
  }


  /**
   * @method : getOrders
   * @required : this method get the data based on order id
    */
  getUserDetails(reqObj) {
    return this.get(this.API_ENDPOINT + `User/GetUserDetail?userKey=${reqObj['userKey']}`)
  }
  getUserToken(userKey) {
    return this.get(this.API_ENDPOINT + `Token/Fetch?userKey=${userKey}`)
  }
  /**
  * @method : `passwordChange`
  * @purpose : for this `required for update profile.`
  */
  passwordChange(userKey, azureUserKey, reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/PasswordChange?loggedInUserId=` + userKey + `&azureUserId=` + azureUserKey, reqObj);
  }

  /**
  * @method : getOrders
  * @required : this method get the data based on order id
   */
  getOrder(reqObj) {
    return this.get(this.API_ENDPOINT + `Transaction/getOrderDetailsByOrderId?orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}&userType=1`)
  }
  /**
  * @method : getOrderDetails
  * @required : this method get the data based on order id
   */
  getOrderDetails(reqObj) {

    return this.get(`assets/api/admin_order_details.json`)
  }


  /**
   * @method : getClientList
   * @required : @this method Get Client List.
   */
  getClients(reqObj) {
    return this.get(`assets/api/client-list.json`)
  }


  // >> Purpose : this method required Get  group company by id
  // >> Required Params :  LoggedInUser & GroupKey
  GetAllGroupCompanyById(reqObj): Observable<any> {
    //
    return this.get(this.API_ENDPOINT + `User/GetAllGroupCompanyById?LoggedInUser=${reqObj['LoggedInUser']}&GroupKey=${reqObj['GroupKey']}`)
  }


  // >> Purpose : this method required Get all group list
  // >> Required Params :  LoggedInUser & GroupKey
  GetClientGroupList(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `User/GetClientGroupList?LoggedInUser=${reqObj['LoggedInUser']}&status=${reqObj['status']}&GroupKey=${reqObj['GroupKey']}`;
    if (reqObj.hasOwnProperty("status") && reqObj['status'] == "All") {
      baseUrl += "&status=All";
    }
    return this.get(baseUrl);
  }



  // >> Purpose : this method required Get all group list base on profile id
  // >> Required Params :  LoggedInUser , LoginUserRole  , ProfileId
  GetGroupByProfileId(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Profile/GetGroupByProfileId?LoggedInUser=${reqObj['LoggedInUser']}&LoginUserRole=${reqObj['LoginUserRole']}&ProfileId=${reqObj['ProfileId']}`);
  }

  // >> Get All Parent List
  // >> Parent List
  GetCompanyList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetClientGroupList?LoggedInUser=${reqObj['LoggedInUser']}&GroupKey=${reqObj['GroupKey']}`);
  }

  // >> Get ALL Parent ALL List
  // >> Parent List
  GetProductListByProfile(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `User/GetAllProductList?LoggedInUser=${reqObj['LoggedInUser']}`;
    if (reqObj['ProfileId']) {
      baseUrl = this.API_ENDPOINT + `User/GetAllProductList?LoggedInUser=${reqObj['LoggedInUser']}&ProfileId=${reqObj['ProfileId']}`
    }
    return this.get(baseUrl);
  }

  // >> Purpose : this method required Get all product list
  // >> Required Params :  LoggedInUser & GroupKey & ProfileId
  GetProductList(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `User/GetAllProductList?LoggedInUser=${reqObj['LoggedInUser']}`;
    // >> Group Id
    if (reqObj['GroupId'] != "" && reqObj['GroupId'] != null) {
      baseUrl = baseUrl + `&GroupId=${reqObj['GroupId']}`;
    }

    // >> Product Id
    if (reqObj['ProfileId'] != "" && reqObj['ProfileId'] != null) {
      baseUrl = baseUrl + `&ProfileId=${reqObj['ProfileId']}`;
    }
    return this.get(baseUrl);
  }

  // >> Purpose : this method required Get profile
  // >> Required Params :  LoggedInUser & ProfileId
  GetProfileDetailById(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `Profile/GetProfileDetailById?LoggedInUser=${reqObj['LoggedInUser']}&ProfileId=${reqObj['ProfileId']}`;
    return this.get(baseUrl);
  }


  // >> Purpose : this method required Get profile
  // >> Required Params :  GetProductListForProfile  & ProfileId
  GetProductListForProfile(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `Profile/GetProductListForProfile?LoggedInUser=${reqObj['LoggedInUser']}&azureUserKey=${reqObj['azureUserKey']}&LoginUserRole=${reqObj['LoginUserRole']}`;

    if (reqObj['GroupId'] && reqObj['GroupId'] != "" && reqObj['GroupId'] != null) {
      baseUrl += `&GroupId=${reqObj['GroupId']}`;
    }
    if (reqObj['ProfileId'] && reqObj['ProfileId'] != "" && reqObj['ProfileId'] != null) {
      baseUrl += `&ProfileId=${reqObj['ProfileId']}`;
    }
    //Base Url
    return this.get(baseUrl);
  }

  // >> Purpose : Add New Profile...
  // >> Required: created new profile..
  AddProfile(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewProfile?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj);
  }


  // >> Purpose : Update  Profile...
  // >> Required: Update new profile..
  UpdateProfile(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/UpdateProfile?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj);
  }

  // >> Purpose : Update  Group...
  // >> Required: Update new profile..
  UpdateGroup(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/UpdateClient?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj);
  }
  // >> Add New Client Group
  AddClientGroup(reqObj): Observable<any> {
    //
    return this.post(this.API_ENDPOINT + `User/AddNewClientBlankGroup?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj);
  }

  AddNewClientGroup(reqObj): Observable<any> {
    //
    return this.post(this.API_ENDPOINT + `User/AddNewClientGroup?LoggedInUser=${reqObj['LoggedInUser']}`, reqObj);
  }
  // >> Add Group Client List
  GetClientDetailById(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetAllGroupCompanyById?LoggedInUser=${reqObj['LoggedInUser']}&GroupKey=${reqObj['GroupKey']}`);
  }
  // Get Domain by group by id
  GetDomainByGroupId(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetDomainByGroupId?LoggedInUser=${reqObj['LoggedInUser']}&groupKey=${reqObj['GroupKey']}`);
  }

  // >> Get ALL Parent Company List
  GetAllParentCompany(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `User/GetAllParentCompany?LoggedInUser=${reqObj['LoggedInUser']}&status="active"`;
    if (reqObj['GroupKey'] != null) {
      baseUrl += `&GroupKey=${reqObj['GroupKey']}`
    }
    return this.get(baseUrl);
  }
  //Get Client By ID
  GetClientById(reqObj): Observable<any> {
    let baseUrl = this.API_ENDPOINT + `User/GetClientById?LoggedInUser=${reqObj['LoggedInUser']}&azureUserKey=${reqObj['azureUserKey']}&LoginUserRole=${reqObj['LoginUserRole']}`;
    if (reqObj['GroupKey'] && reqObj['GroupKey'] != "" && reqObj['GroupKey'] != null) {
      baseUrl += `&GroupKey=${reqObj['GroupKey']}`;
    }
    if (reqObj['profileKey'] && reqObj['profileKey'] != "" && reqObj['profileKey'] != null) {
      baseUrl += `&profileKey=${reqObj['profileKey']}`;
    }
    //Base Url
    return this.get(baseUrl);
  }


  addReleaseNotes(reqData): Observable<any> {
    return this.post(this.API_ENDPOINT + `ReleaseNotes/AddReleaseNote?LoggedInUser=` + reqData['LoggedInUser'], reqData);
  }






  updateProfilePic(reqData): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/ProfilePicUpload?loggedInUserId=` + reqData['loggedInUserId'] + `&azureUserId=` + reqData['azureUserId'], reqData);
  }

  ProfileUpdateExtension(loggedInUserId, azureUserId, reqData): Observable<any> {
    return this.patch(this.API_ENDPOINT + `UserUpdateExtension?loggedInUserId=` + loggedInUserId + `&azureUserId=` + azureUserId, reqData);
  }

  ProfileAddExtension(loggedInUserId, azureUserId, reqData): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AddExtension?loggedInUserId=` + loggedInUserId + `&azureUserId=` + azureUserId, reqData);
  }

  getCompanyList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `/DataLoad/GetParentChildCompanyTree?userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`);
  }


  GetReleaseNote(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `ReleaseNotes/GetReleaseNote?LoggedInUser=` + reqObj['LoggedInUser']);
  }

  GetUserGuide(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `ReleaseNotes/GetUserGuideDocs?LoggedInUser=${reqObj['LoggedInUser']}&Project=${reqObj['Project']}`);
  }

  addUserGuide(reqData): Observable<any> {
    return this.post(this.API_ENDPOINT + `ReleaseNotes/AddUserGuide?LoggedInUser=` + reqData['LoggedInUser'], reqData);
  }


  DeleteReleaseNote(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `ReleaseNotes/DeleteReleaseNote?LoggedInUser=${reqObj['LoggedInUser']}&ReleaseNotesId=${reqObj['ReleaseNotesId']}`, reqObj);
  }

  DeleteUserGuide(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `ReleaseNotes/DeleteUserGuide?LoggedInUser=${reqObj['LoggedInUser']}&DocumentId=${reqObj['DocumentId']}`, reqObj);
  }

  GetAllProductListFsadminStaff(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetAllProductListFsadminStaff?LoggedInUser=${reqObj['loggedInUserId']}&azureUserId=${reqObj['editUserAzureKey']}`);
  }
}
