import { AbstractControl, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';

/**
 * @Validation `Username` check this method
 */
export class UsernameValidator {
  constructor() {
    return [
      '',
      Validators.compose(
        [

          Validators.required

        ],
      ),
    ];
  }
}

/**
 * @Validation : PasswordValidator
 */
export class PasswordValidator {
  constructor() {
    return [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(256),
      ]

      )
    ];
  }
}
//// [
//Validators.pattern("[A-Za-z0-9]{1,}$"),
//  Validators.minLength(5),
//  Validators.maxLength(8),
//  Validators.required
//                ],
/**
 * @Validation : ForgotPasswordValidator
 */

export class ForgotPasswordValidator {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.pattern("[a-zA-z0-9]{1,}@{1}[A-Za-z]{2,10}[.]{1}[^(web)|^(\s)]{2,5}"),
          Validators.minLength(8),
          Validators.maxLength(256),
          Validators.required
        ],
      )
    ];
  }
}
/**
 * @Validation : ForgotPasswordValidator
 */

export class ForgotUsernameValidator {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.pattern("[a-zA-z0-9]{1,}@{1}[A-Za-z]{2,10}[.]{1}[^(web)|^(\s)]{2,5}"),
          Validators.minLength(8),
          Validators.maxLength(256),
          Validators.required
        ],
      )
    ];
  }
}


/**
 * @method: confirm Account
 */
export class PasswordRequiredWithPattern {
  constructor(str: any = '') {
    return [
      str,
      Validators.compose(
        [
          //  Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"),
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(256)
        ],
      )];
  }
}


/**
 * @Validation `New Transaction or New Loan for all`
 * @default : this is not required.
 */
export class NewTransactionsValidation {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.pattern("^[0-9]*$"),
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(15)
        ],
      )];
  }
}
/**
 * @Validation `FirstName`
 * @default : this is  required.
 */
export class FirstName {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required
        ],
      )];
  }
}
/**
 * @Validation `Required`
 * @default : this is  required.
 */
export class Required {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required
        ],
      )];
  }
}

/**
 *@minlength: 2

 * */
export class MinLength {
  constructor(length = 2) {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.minLength(length)
        ],
      )];
  }
}

export class UserName {
  constructor(length = 3) {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.minLength(3)
        ],
      )];
  }
}


//Azure UserName
export class AzureUserName {
  constructor(length = 3) {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[a-zA-Z0-9._-]+$')
        ],
      )];
  }
}


/**
 * @Validation `Required`
 * @default : this is  required.
 */
export class NotRequired {
  constructor(str: any = '') {
    return [str];
  }
}


export class AddressParentRequired {
  constructor(length = 4) {
    return [
      '',
      Validators.compose(
        [
          Validators.minLength(length)
        ],
      )];
  }
}

export class CityParentRequired {
  constructor(length = 2) {
    return [
      '',
      Validators.compose(
        [
          Validators.pattern("[a-z A-Z .]*$"),
          Validators.minLength(length)
        ],
      )];
  }
}




export class DomainValid {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          //Validators.pattern("/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/"),
          Validators.required
        ],
      )];
  }
}
export class isBoolean {
  constructor(str = false) {
    return [str];
  }
}

/**
 * @Validation `NotRequiredNumber`
 * @default : this is  required.
 */
export class NotRequiredNumber {
  constructor(str: any = '') {
    return [str];
  }
}

/**
 * @Validation `NotRequiredDate`
 * @default : this is  required.
 */
export class NotRequiredFundingDate {
  constructor(str: any = '') {
    return [str];
  }
}



/**
 * @method: confirm Account
 */
export class ConfirmField {
  constructor(str: any = '') {
    return [
      str,
      Validators.compose(
        [
          Validators.required
        ],
      )];
  }
}



/**
 * @method: confirm Account
 */
export class PasswordRequired {
  constructor(str: any = '') {
    return [
      str,
      Validators.compose(
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(256),
        ],
      )];
  }
}



/**
 * @method: confirm Account
 */
export class ImagesValid {
  constructor() {
    return [
      undefined,
      Validators.compose(
        [
          Validators.required,
          FileValidator.maxContentSize(104857600)
        ],
      )];
  }
}

/**
 * @Validation `AccNumberValid`
 * @default : this is not but valid pattern.
 */
export class AccNumberValid {
  constructor(str: any = '') {
    return [
      '',
      Validators.compose(
        [
          Validators.pattern("^[0-9]{1,}$"),
          Validators.minLength(9),
          Validators.maxLength(15),
        ],
      )];
  }
}


/**
 * @Validation `ZipValid`
 * @default : this is not but valid pattern.
 */
export class ZipValid {
  constructor(isRequired: boolean = false) {
    ///const patternStr = '^[0-9]{1,}$';
    if (isRequired) {
      // If this is Required then
      return [
        '',
        Validators.compose(
          [
            Validators.required,
            customZipCodeValidator()
            //Validators.minLength(5),
            //Validators.maxLength(6),
          ],
        )];
    } else {
      // Not Required Then Called this block
      return [
        '',
        Validators.compose(
          [
            //Validators.pattern(patternStr),
            customZipCodeValidator()
            //Validators.minLength(5),
            //Validators.maxLength(6),
          ],
        )];
    }

  }
}

/**
 * @Validation `loanamount`
 * @default : this is not but valid pattern.
 */
export class LoanAmountRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(15),
          // Validators.pattern("^([0-9.$,]+)$"),

        ]
      )];
  }
}

/**
 * @Validation `phone`
 * @default : this is not but valid pattern.
 */
export class PhoneFieldRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,

        ]
      )];
  }
}
/**
 * @Validation `contact`
 * @default : this is not but valid pattern.
 */
export class ContactFieldRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.minLength(10)
        ]
      )];
  }
}

/**
 * @Validation `userrole`
 * @default : this is not but valid pattern.
 */
export class UserRoleRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,

        ]
      )];
  }
}
/**
 * @Validation `ClientRolename`
 * @default : this is not but valid pattern.
 */
export class RoleNameRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,

        ]
      )];
  }
}
/**
 * @Validation `ParentCompany`
 * @default : this is not but valid pattern.
 */
export class ParentCompanyRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,

        ]
      )];
  }
}
export class ChildCompanyRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,

        ]
      )];
  }
}





/**
 * @Validation `Email` check this method
 */
export class EmailValidator {
  //  constructor() {
  //      return [
  //          '',
  //          Validators.compose(
  //              [
  //                  Validators.required,
  //                  Validators.email,
  //                  //Validators.pattern("[a-zA-z0-9]{1,}@{1}[A-Za-z]{2,10}[.]{1}[^(web)|^(\s)]{2,5}"),

  //              ],
  //          ),
  //      ];
  //}

  constructor(isRequired = true) {
    let emailReg = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;|,\s*|\s*$))*$/;
    if (isRequired) {
      return [
        '',
        Validators.compose(
          [
            Validators.required,
            Validators.email,
            Validators.pattern("^[a-zA-z0-9._-]+@[a-zA-z0-9.-]+\\.[a-zA-z]{2,}$")

          ],
        ),
      ];
    } else {
      return [
        '',
        Validators.compose(
          [
            Validators.pattern("^[a-zA-z0-9._-]+@[a-zA-z0-9.-]+\\.[a-zA-z]{2,}$")

          ],
        ),
      ];
    }
  }
}


export class companyEmail {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.email,
          //Validators.pattern("[a-zA-z0-9]{1,}@{1}[A-Za-z]{2,10}[.]{1}[^(web)|^(\s)]{2,5}"),
          Validators.pattern("^[a-zA-z0-9._-]+@[a-zA-z0-9.-]+\\.[a-zA-z]{2,}$")
        ],
      ),
    ];
  }
}

export class RequiredSelect {
  constructor() {
    return [
      null,
      Validators.compose(
        [
          Validators.required
        ],
      )];
  }
}

export class CityRequired {
  constructor(length = 2) {
    return [
      '',
      Validators.compose(
        [
          Validators.pattern("[a-z A-Z]*$"),
          Validators.required,
          Validators.minLength(length)
        ],
      )];
  }
}

/**
 * @Validation `ZipValid`
 * @default : this is not but valid pattern.
 */
export class ZipValidNew {
  constructor(isRequired = false) {
    const patternStr = '^[0-9]{1,}$';
    if (isRequired) {

      // If this is Required then
      return [
        '',
        Validators.compose(
          [
            Validators.required,
            Validators.pattern(patternStr),
            Validators.minLength(4),
            Validators.maxLength(5),
          ],
        )];
    } else {
      // Not Required Then Called this block
      return [
        '',
        Validators.compose(
          [
            Validators.pattern(patternStr),
            Validators.minLength(4),
            Validators.maxLength(5),
          ],
        )];
    }

  }
}

/**
 * @Validation `ABA Routing Number`
 * @default : this is not but valid pattern.
 */
export class AbaRoutingRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.pattern("^[0-9]{1,}$"),
          Validators.minLength(9)
          //Validators.pattern("^[1-9]\d*(\.\d+)?$"),

        ]
      )];
  }
}


export class RequiredNumber {
  constructor(isRequired = true) {

    if (isRequired) {
      return [
        '',
        Validators.compose(
          [
            Validators.required,
            Validators.pattern("^[0-9]{1,}$")
          ],
        )];
    } else {
      return [
        '',
        Validators.compose(
          [
            Validators.pattern("^[0-9]{1,}$")
          ],
        )];

    }



  }
}


export function customZipCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const zipCodeVal = control.value;

    if (!zipCodeVal) {
      return null;
    }

    let resVal: boolean = false;
    let regex1 = /^\d(?!(0))\d{4}$/;
    let regex2 = /^[1-9][0-9]*$/;      //for 1230 type number only
    if (regex1.test(zipCodeVal)) {  //check for zeros
      resVal = true;
    } else {
      resVal = regex2.test(zipCodeVal) ? true : false;
    }
    return !resVal ? { validZip: true } : null;
  }
}



