

import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError, filter, finalize, take, switchMap } from "rxjs/operators";
import { constant } from "../constants/const";
import { USER } from 'src/app/constants/const';
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService
  ) {
  }
  private AUTH_HEADER = "Authorization";
  private token = "secrettoken";
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    req = req.clone(this.jwt());
    return next.handle(req);

  }

  /**
   * @method : jwt
   * @purpose:- Set Authoriztion as Token and also set as Authoriztion..
   */
  private jwt() {
    var self = this;
    // var access_token = self.cookieService.get(USER);
    // if(access_token && access_token != ''){
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    if (token) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
          'User-Id': '125'
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }
    // }
    // else{
    //   // window.location.href = "/login";

    //   if(window.location.pathname != '/login'){
    //     this.cookieService.deleteAll('/');
    //     localStorage.clear();
    //     window.location.href = '/login';
    //   }
    // }
  }
}
