import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class Utility {
    login$ = new Subject<any>();
    isLoad$ = new Subject<any>();
    isClientFormValid$ = new Subject<any>();
    redirectProfile$ = new Subject<any>();
    tabChanged$ = new Subject<any>();
    profilePic$ = new Subject<any>();
     // For User Create
     userCreate$ = new Subject<any>();
     // For Client Create
     clientCreate$ = new Subject<any>();
     clientList$ = new Subject<any>();
      profileUpdate$ = new Subject<any>();
      groupUpdate$ = new Subject<any>();
      filterSetModal$ = new Subject<any>();
     subscriptionLoad$ = new Subject<any>();
      // >> Client Update
  clientUpdate$ = new Subject<any>();
  underwriterCreate$ = new Subject<any>();
  closingAgentUpdate$ = new Subject<any>();
  wireAccountUpdate$ = new Subject<any>();

    constructor() {
    }
    /**
     * @method:  set  logout.
     */
    setLogout() {
        this.login$.next(true);
    }
    /**
     * @method: `Logout`
     * @requires: for user logout as Async based..
     */
    logout(): Observable<any> {
        return this.login$.asObservable();
    }
  /**
   *@get User Id
   * */
  getUserId() {
    if (localStorage.getItem('userKey')) {
      return localStorage.getItem('userKey');
    } else {
      return null
    }
  }

  /**
   *@get User Id
   * */
  getUserRole() {
    if (localStorage.getItem('userDetailsLocal')) {
      let userData = JSON.parse(localStorage.getItem('userDetailsLocal'));
      return userData['roleName'];
    } else {
      return null
    }
  }

  getUserType() {
    if (localStorage.getItem('userType')) {
      return JSON.parse(localStorage.getItem('userType'));
    } else {
      return 1;
    }
  }
    /**
     * @function : isLoad
     */
  isLoad(value = false) {
        this.isLoad$.next(value)
    }

    /**
     * @loaderStatusGet
     */
    loaderStatusGet(): Observable<any> {
        return this.isLoad$.asObservable();
    }

    /**
     * @client info form valid.
     * @set as valid or not.
     */
    setClientFormValid(status) {
        this.isClientFormValid$.next(status)
    }
    /**
     * @method : getClientForm
     * get the data
     */
     getClientFormValid(status) {
        return this.isClientFormValid$.asObservable();
     }


    // * @Profile Button On `click as Header`
    /**
     * @Set
     * @Util : for set tab as click on profile but for that created it.
     * `Name` : `redirectProfile$:`
     */
     redirectSetProfile(tabNumber = 0) {
        this.redirectProfile$.next(tabNumber);
     }
    /**
     * @Get
     * @Util : for the get as set Profile method
     * `Name` : `redirectGetProfile$:`
     */
     redirectGetProfile() {
        return this.redirectProfile$.asObservable();
     }


     setTabChanged(selectIndex) {
        this.tabChanged$.next(selectIndex);
     }

     getTabChanged() {
        return this.tabChanged$.asObservable();
     }


     // For User Create
    setCreateUser(res = false) {
      this.userCreate$.next(res);
     }

     // For User Create
      getCreateUser(){
        return this.userCreate$.asObservable();
      }

     // For Client Create
     
      setCreateClient(){
        this.clientCreate$.next(true);
      }

      getCreateClient(){
        return this.clientCreate$.asObservable();
  }

  // Set Client List.
  setClientList(array) {
    this.clientList$.next(array)
  }
  // Get Client List
  getClientList() {
    return this.clientList$.asObservable();
  }

  // Set Profile
  setProfileUpdate(obj) {
    this.profileUpdate$.next(obj);
  }

  //@Get Profile
  getProfileUpdate() {
    return this.profileUpdate$.asObservable();
  }


  // Set Profile
  setGroupUpdate(obj) {
   
    this.groupUpdate$.next(obj);
  }

  //@Get Profile
  getGroupUpdate() {
   
    return this.groupUpdate$.asObservable();
  }


  //@Get Client
  getClientUpdateAsync() {
    return this.clientUpdate$.asObservable();
  }

  //@Set Client
  setClientUpdateAsync(obj) {
    this.clientUpdate$.next(obj);
  }


  getProfile() {
    return this.profilePic$.asObservable();
  }

  setProfile(obj) {
    this.profilePic$.next(obj)
  }

  // >> Get Filter Modal
  getFilterModal() {
    return this.filterSetModal$.asObservable();
  }

  // >> Set Filter Modal
  setFilterModal(obj) {
    this.filterSetModal$.next(obj)
  }

  // Get Subscrion Load
  getSubsribeLoad() {
    return this.subscriptionLoad$.asObservable();
  }

  //Set Subscription Load
  setSubsribeLoad(obj) {
    this.subscriptionLoad$.next(obj);
  }

  // For Underwriter
  setUnderwriter(res = false) {
    this.underwriterCreate$.next(res);
  }
  getUnderwriter() {
    return this.underwriterCreate$.asObservable();
  }

  // For ClosingAgent
  setClosingAgent(res = false) {
    this.closingAgentUpdate$.next(res);
  }
  getClosingAgent() {
    return this.closingAgentUpdate$.asObservable();
  }

  // For Wire Account
  setWireAccount(res = false) {
    this.wireAccountUpdate$.next(res);
  }
  getWireAccount() {
    return this.wireAccountUpdate$.asObservable();
  }

}
