export  class ClientLabel {
    public static Label = {
      loginTitle: 'Login',
      forgetUsernameLink: 'Forgot Username?',
      forgetPasswordLink: 'Forgot Password?',
      newTransactionExistMsg:'(Before you proceed please check if loan exists in our database)',
      propertyAddressText:'PropertyAddress:',
      cityFieldText:'City:',
      stateFieldText:'State:',
      zipFieldText:'Zip:',
      firstNameText:'First Name:',
      borrowerInfoMsg:'Would you like to add Additional Borrower Information?',
      loanInfoMsg:'Is there a Second Mortgage (same bank) associated  with this loan? (check if yes)',
      loanMortgageButton:'Is this Second Mortgage insured?',
      
     
    }
}