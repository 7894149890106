import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  isBoolean, NewTransactionsValidation,
  UsernameValidator,
  PasswordValidator,
  Required,
  NotRequired,
  ForgotPasswordValidator,
  ForgotUsernameValidator,
  ConfirmField, NotRequiredNumber, PasswordRequired,
  ImagesValid, NotRequiredFundingDate,
  AccNumberValid, ZipValid, LoanAmountRequired,
  EmailValidator, MinLength, DomainValid, UserName,
  AzureUserName, companyEmail,
  CityParentRequired, AddressParentRequired, PasswordRequiredWithPattern, RequiredSelect,
  CityRequired, ZipValidNew, ContactFieldRequired, AbaRoutingRequired, RequiredNumber
} from '../../shared/classes/validation.class'


@Injectable()
export class FormService {
  constructor() { }

  createLoginForm(): FormGroup {
    return new FormBuilder().group({
      username: new UsernameValidator(),
      password: new PasswordValidator(),
    });
  }
  createForgotPasswordForm(): FormGroup {
    return new FormBuilder().group({
      email: new ForgotPasswordValidator()
    });
  }
  createForgotUsernameForm(): FormGroup {
    return new FormBuilder().group({
      email: new ForgotUsernameValidator()
    });
  }

  createRegistrationForm(): FormGroup {
    return new FormBuilder().group({
      username: new UsernameValidator(),
      password: new PasswordValidator(),
    });
  }

  createNewTransForm(): FormGroup {
    return new FormBuilder().group({
      loan_number: new NewTransactionsValidation()
    });
  }
  /**
   * @function `createClientInfo`
   */
  createClientInfo(): FormGroup {
    return new FormBuilder().group({
      state_code: new NotRequired(),
      clientKey: new NotRequired(),
      alternet_contact_id: new NotRequired(),
      alternet_contact_first_name: new NotRequired(),
      alternet_contact_last_name: new NotRequired(),
      alternet_contact_email: new Required(),
      alternet_contact_phone: new NotRequired()
    });
  }

  /**
   * @function `createBorrowerForm`
   */
  createBorrowerForm(): FormGroup {
    return new FormBuilder().group({
      changeMemo: new NotRequired(),
      primaryBorrower: new FormBuilder().group({
        firstName: new MinLength(2),
        middleName: new NotRequired(),
        lastName: new MinLength(2),
        isNonBorrowerParty: new NotRequired(false)
      }),
      secondaryBorrower: new FormBuilder().group({
        firstName: new NotRequired(),
        middleName: new NotRequired(),
        lastName: new NotRequired(),
        isNonBorrowerParty: new isBoolean(false)
      }),
      additinalUserChk: new NotRequired(false),
      quarternaryBorrower: new FormBuilder().group({
        firstName: new NotRequired(),
        middleName: new NotRequired(),
        lastName: new NotRequired(),
        isNonBorrowerParty: new NotRequired(false)
      }),
      additionalBorrower: new FormBuilder().group({
        firstName: new NotRequired(),
        middleName: new NotRequired(),
        lastName: new NotRequired(),
        isNonBorrowerParty: new NotRequired(false)
      })

    });
  }

  /**
   * @method : createPropertyForm
   */
  propertyForm(): FormGroup {
    return new FormBuilder().group({
      changeMemo: new NotRequired(),
      address1: new MinLength(8),
      address2: new NotRequired(),
      city: new MinLength(4),
      state: new Required(),
      zipCode: new MinLength(5)
    });
  }
  /**
  * @method : notesForm
  */
  notesForm(): FormGroup {
    return new FormBuilder().group({
      notes: new Required()
    });
  }

  /**
   * @method : loanForm
   */
  loanForm(): FormGroup {
    return new FormBuilder().group({
      changeMemo: new NotRequired(),
      loanAmount: new LoanAmountRequired(),
      fundingDate: new NotRequiredFundingDate(), //NotRequiredFundingDate
      isSecondMortgage: new NotRequired(false),
      secondMortgageLoanNumber: new NotRequired(),
      isSecondMortgageInsured: new NotRequired('yes'),
      secondMortgageLoanAmount: new NotRequired()
    });
  }

  /**
   * @method : loanForm
   */
  newClosingAgentForm(): FormGroup {
    return new FormBuilder().group({
      closingAgentInfo: new FormBuilder().group({
        companyName: new Required(),
        email: new companyEmail(),
        phone: new ContactFieldRequired(),
        address2: new NotRequired(),
        state: new RequiredSelect(),
        webSite: new NotRequired(),
        address: new MinLength(),
        city: new CityRequired(2),
        zip: new ZipValid(true)
      }),
      accountHolderInfo: new FormBuilder().group({
        accountName: new Required(),
        accountNumber: new RequiredNumber(), //new AccNumberValid(),
        confirmAccNumber: new RequiredNumber(),
        phone: new NotRequired(),
        city: new NotRequired(),
        zipCode: new ZipValid(false),
        state: new NotRequired(),
        subAccountName: new NotRequired(),
        subAccountNumber: new RequiredNumber(false)
      }),
      bankInfo: new FormBuilder().group({
        bankName: new Required(),
        abaRouting: new AbaRoutingRequired(),
        confirmRouting: new NotRequiredNumber(),
        intermediaryBankName: new NotRequired(),
        accountNumber: new RequiredNumber(false)
      })

    });
  }


  /**
  * @method : adduser
  */
  addNewUser(): FormGroup {
    return new FormBuilder().group({

      groupId: new NotRequired(),
      profileId: new NotRequired(),
      stateGroup: new NotRequired(),
      userFirstName: new Required(),
      userLastName: new Required(),
      //userAddress: new Required(),
      userAddress: new NotRequired(),
      userAddress2: new NotRequired(),
      userCity: new CityParentRequired(),
      userState: new NotRequired(),
      userZipCode: new NotRequired(),
      userEmail: new companyEmail(),
      //userPhone: new Required(),
      userPhone: new NotRequired(),
      extension: new NotRequired(),
      userRole: new Required(),
      //  password: new PasswordRequired(),
      companyName: new NotRequired(),
      spcCheckedName: new NotRequired(),
      guardinCheckedName: new NotRequired(),
      //  userName: new AzureUserName()
    });
  }
  /**
* @method : addClient
*/
  addNewClientRole(): FormGroup {
    return new FormBuilder().group({
      rolename: new Required(),
      lowerRoleName: new Required(),
      clientRole: new Required()
    });
  }
  /**
     * @method : AddNewParent
     */
  addNewParent(): FormGroup {
    return new FormBuilder().group({
      groupName: new Required(),
      clientName: new MinLength(3),
      //clientEmail: new companyEmail(),
      clientEmail: new EmailValidator(false),
      clientPhone: new NotRequired(),
      clientMobile: new NotRequired(),
      clientAddress: new AddressParentRequired(),
      clientAddress2: new NotRequired(),
      clientCity: new CityParentRequired(),
      clientState: new Required(),
      clientZipCode: new ZipValid(false),
      spcCheckedName: new NotRequired(),
      guardinCheckedName: new NotRequired()
    });
  }


  /**
   * @method : uploadForm
   */
  uploadForm(): FormGroup {
    return new FormBuilder().group({
      cpl: new Required(),
      wire: new Required(),
      e_o: new NotRequired(),
      crimes: new NotRequired(),
      state_licensing: new NotRequired(),
      bank_ref: new NotRequired()
    });
  }

  updateUploadForm(): FormGroup {
    return new FormBuilder().group({
      document_type: new Required(),
      file: new Required()
    });
  }
  /**
   * @method: underWriterForm
   */
  underWriterForm(): FormGroup {
    return new FormBuilder().group({
      changeMemo: new NotRequired(),
      company_name: new Required(),
      company_id: new Required()
    });
  }

  /**
   * @method : `addWireAccount()`
   */
  addWireAccount(): FormGroup {
    return new FormBuilder().group({
      accountHolderInfo: new FormBuilder().group({
        accountName: new Required(),
        accountNumber: new Required(), //new AccNumberValid(),
        confirmAccNumber: new Required(),
        phone: new NotRequired(),
        city: new NotRequired(),
        zipCode: new ZipValid(),
        state: new NotRequired(),
        subAccountName: new NotRequired(),
        subAccountNumber: new NotRequired()
      }),
      bankInfo: new FormBuilder().group({
        bankName: new NotRequired(),
        abaRouting: new NotRequiredNumber(),
        confirmRouting: new NotRequiredNumber(),
        intermediaryBankName: new NotRequired(),
        accountNumber: new NotRequired()
      }),

    });
  }

  /**
   * @method : `closingAgentInfo`
   */

  closingAgentForm(): FormGroup {
    return new FormBuilder().group({
      temp: new NotRequired(),
      company_name: new NotRequired(),
      company_id: new NotRequired(),
      escrowNumber: new NotRequired(),
      agent_id: new Required(),
      title: new NotRequired(),
      state_code: new NotRequired(),
      extra: new FormBuilder().group({
        email: new NotRequired(),
        telephone: new NotRequired(),
        first_name: new NotRequired(),
        last_name: new NotRequired(),
        contatEmail: new NotRequired(),
      }),
      address: new FormBuilder().group({
        address: new NotRequired(),
        address2: new NotRequired(),
        city: new NotRequired(),
        state_id: new NotRequired(),
        zip: new NotRequired()
      }),
    });
  }

  /**
   * @method: underWriterForm
   */
  profileForm(): FormGroup {
    return new FormBuilder().group({
      displayName: new NotRequired(),
      surname: new NotRequired(),
      username: new NotRequired(),
      userPrincipalName: new NotRequired(),
      mobilePhone: new NotRequired(),
      businessPhones: new NotRequired(),
      extension: new NotRequired(),
      fax: new NotRequired(),
      jobTitle: new NotRequired(),
      profileImage: new NotRequired(),
      roleName: new NotRequired()
    });
  }


  /**
   * @method: changePassword
   */
  changePasswordForm(): FormGroup {
    return new FormBuilder().group({
      oldPassword: new PasswordRequiredWithPattern(),
      newPassword: new PasswordRequiredWithPattern(),
      confirmPassword: new PasswordRequiredWithPattern()
    });
  }

  /**
   * @method : ContatUsForm
   */

  contactUsForm(): FormGroup {
    return new FormBuilder().group({
      phone: new Required(),
      firstName: new NotRequired(),
      lastName: new NotRequired(),
      email: new Required(),
    });
  }


  /**
   * @metthod :  formService
   * @purpose :  this is created service.
   *
   */


  sendEmail(): FormGroup {
    return new FormBuilder().group({
      message: new Required(),
      subject: new NotRequired(),
      alternetEmail: new NotRequired(),
      email: new EmailValidator(),
      wire: new NotRequired(),
      cpl: new NotRequired(),
      emailType: new Required()
    });
  }


  /**
   * @method: changePassword
   */
  resetPassword(): FormGroup {
    return new FormBuilder().group({
      newPassword: new PasswordRequired(),
      confirmPassword: new PasswordRequired()
    });
  }

  // >> New Profile Form
  // >> Profile Form Form Create And Required
  newProfileForm(): FormGroup {
    return new FormBuilder().group({
      profileName: new Required(),
      profiletype: new Required(),
      clientGroupName: new NotRequired()
    });
  }

  /**
  * @method: releaseNoteForm
  */
  releaseNoteForm(): FormGroup {
    return new FormBuilder().group({
      releaseNote: new Required(),
      document: new Required(),
      description: new NotRequired(),
      version: new Required(),
      docName: new NotRequired()
    });
  }

  /**
* @method: userGuideForm
*/
  userGuideForm(): FormGroup {
    return new FormBuilder().group({
      userGuideTxt: new Required(),
      document: new NotRequired(),
      description: new Required()
    });
  }


  /**
    * @method : createPropertyForm
    */
  underwriterForm(): FormGroup {
    return new FormBuilder().group({
      companyName: new Required(),
      address: new Required(),
      zipCode: new ZipValid(true),
      state: new RequiredSelect(),
      city: new CityRequired(2),
      address2: new NotRequired(),
      rating: new NotRequired()
    });
  }


  /**
  * @method : addClosingAgentContactForm
  */
  closingAgentContactForm(): FormGroup {
    return new FormBuilder().group({
      firstName: new NotRequired(),
      lastName: new NotRequired(),
      email: new companyEmail(),
      phone: new ContactFieldRequired()
    });
  }




}
