<header>
    <div class="container-fluid top-header">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="logo"><img [src]="image$ | async" alt="logo"></div>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="header-right">
                    <div class="welcome-user float-left">

                        <div class="dropdown">

                        </div>
                         <div class="profile-bg">
                            <button mat-button [matMenuTriggerFor]="menu"><img [src]="profilePic$ | async" alt="profile"></button>
                            <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="redirectProfile('profile',0)"><i class="material-icons">account_box</i>Profile</button>
                            <button mat-menu-item (click)="redirectProfile('profile',1)"><i class="material-icons">settings</i>Setting</button>
                            </mat-menu>
                        </div>
                        <div class="welcome-lastseen">
                          <div class="welcome">


                            {{ (fullName$ | async) }}
                          </div>
                            <div class="last-seen">
       Last Login : <span>{{ (dateText$ | async) }}</span>
   </div>
                            
                          </div>
                    </div>
                    <div class="logout float-left">
                            <a href="javascript:void(0)" (click)="logout()"><i class="material-icons">exit_to_app</i> Logout</a>
                    </div>
                </div>
                    
            
            </div>
        </div>
    </div>

    <div class="container-fluid header-menu">
        <div class="row">
            <div class="col-lg-12">
              <ul>
                <li *ngIf="isGroupShow$ | async"><a href="javascript:void(0)" [routerLink]="['/client/group-list']" [routerLinkActive]="'active'">Group</a></li>
                <li><a href="javascript:void(0)" [routerLink]="['/client/list']" [routerLinkActive]="'active'">Client</a></li>
                <li><a href="javascript:void(0)" [routerLink]="['/client/profile-list']" [routerLinkActive]="'active'">Profile</a></li>
                <li><a href="javascript:void(0)" [routerLink]="['/users/list']" [routerLinkActive]="'active'">Users</a></li>
                <li *ngIf="currRoleName == 'FS Admin'"><a href="javascript:void(0)" [routerLink]="['/client/underwriter']" [routerLinkActive]="'active'">Underwriter</a></li>
                <li *ngIf="currRoleName == 'FS Admin'"><a href="javascript:void(0)" [routerLink]="['/client/closingagent']" [routerLinkActive]="'active'">Closing Agent</a></li>
                <li *ngIf="currRoleName == 'FS Admin'"><a href="javascript:void(0)" [routerLink]="['/client/info']" [routerLinkActive]="'active'">Info</a></li>

              </ul>

               
            </div>
        </div>

    </div>
</header>
