<header *ngIf="isFailed">
    <div class="container-fluid top-header">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="logo"><img src="assets/img/logo.png" alt="logo"></div>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="header-right">
                    <div class="welcome-user float-left">

            
                         <div class="welcome-lastseen">
                           <div class="welcome">

                            
                           </div>
                           <!--<div class="last-seen">
      Last Login : <span>{{ (loginUser$ | async)?.last_login_date }}</span>  <span> {{ (loginUser$ | async)?.last_login_time }}</span>
  </div>-->
                          
                         </div>
                    </div>
                    <div class="logout float-left">
                            <a href="javascript:void(0)" (click)="login()"><i class="material-icons">exit_to_app</i> Login</a>
                    </div>
                </div>
                    
            
            </div>
        </div>
    </div>
<hr>
    <p *ngIf="isFailed"  style="color:red;text-align: center;font-size: large;">
        {{errorMessage}}
    </p>
    
</header>

