import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, EmailValidator } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { Validations } from '../../constants/validations'
import { LoginService } from '../../services/login.service';
import { ToastrService } from "ngx-toastr";
import { Utility } from 'src/app/async/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordform: FormGroup;
  submitted = false;
  error = '';
  saveInProgress: boolean = false;
  validMsg = Validations;
  constructor(fb: FormBuilder, private shareApi: SharedService,
    public loginService: LoginService,
    private router: Router,
    private toast: ToastrService,
    public loader: NgxUiLoaderService) {
    this.forgotpasswordform = fb.group({
      email: ["", Validators.required]
    });
  }

  ngOnInit() {
  }
  onSubmit() {
    var that = this;
    if (this.forgotpasswordform.valid) {
      console.log(this.forgotpasswordform.value);
    }
    that.saveInProgress = true;
    this.loader.start();
    debugger;
    setTimeout(() => {
      this.loginService.resetPassword(this.forgotpasswordform.value.email).then((response) => {
        this.toast.success("Please check your email for the password reset link.");
        this.router.navigate(["/login"]);
      })
        .catch((error) => that.toast.error(error.error.message, "Error"))
        .finally(() => {
          that.saveInProgress = false;
          this.loader.stop();
        });
    }, 200);
  }

}



