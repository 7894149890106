import { BrowserModule } from '@angular/platform-browser';
import { NgModule, InjectionToken } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
// For Redux
import { CoreRoutingModule } from './core/core.routing';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { FormService } from './services/forms/form.service';


import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { SharedMaterialModule } from './shared/material/material.module';
import { RouterModule } from '@angular/router';
import { AgGridMyModule } from './shared/grid/ag-grid.module';
import { ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { Utility } from './async/utility';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; 


@NgModule({
  declarations: [
    AppComponent
 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    CoreModule,
    CoreRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule,
    SharedMaterialModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgIdleKeepaliveModule.forRoot()
  ],
 
  providers: [FormService, Utility],
  
  bootstrap: [AppComponent]
 

  
})
export class AppModule { }
