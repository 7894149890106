<h2 mat-dialog-title>Add Wire Account</h2>
<form style="padding:0 24px;" [formGroup]="addWireAccountForm" novalidate (ngSubmit)="addWireAccount()">
  <mat-dialog-content class="mat-typography addNewcl">


    <div class="row">
      <div class="col-lg-12">
        <h4 class="mb-0">Account Holder Information</h4>
      </div>
    </div>
    <div class="row" formGroupName="accountHolderInfo">

      <div class="col-lg-6">
        <label class="required-field">Name on Account:</label>
        <input placeholder="" type="text" formControlName="accountName">
        <div *ngIf="accountHolderForm['accountName']?.touched">
          <mat-error class="error-cus" *ngIf="accountHolderForm['accountName']?.errors?.required">
            This field is required.
          </mat-error>
        </div>
      </div>

      <div class="col-lg-6">
        <label class="required-field">Account #:</label>
        <input matInput placeholder="" type="text" formControlName="accountNumber" autocomplete="off" minlength="4" maxlength="30">
        <div *ngIf="accountHolderForm['accountNumber']?.touched && accountHolderForm['accountNumber'].errors">
          <mat-error class="error-cus" *ngIf="accountHolderForm['accountNumber']?.errors?.required">
            This field is required.
          </mat-error>
          <mat-error class="error-cus" *ngIf="accountHolderForm['accountNumber']?.errors?.pattern">
            This field is invalid.
          </mat-error>
          <mat-error class="error-cus" *ngIf="!accountHolderForm['accountNumber']?.errors?.pattern && accountHolderForm['accountNumber']?.errors?.minlength">
            Minimum length is 4.
          </mat-error>
          <mat-error class="error-cus" *ngIf="!accountHolderForm['accountNumber']?.errors?.pattern && accountHolderForm['accountNumber']?.errors?.maxlength">
            Maximum length is 30.
          </mat-error>
        </div>
      </div>


      <div class="col-lg-6">
        <label>SubAccount Name:</label>
        <input matInput placeholder="" type="text" formControlName="subAccountName" style="margin-top:6px;">
      </div>

      <div class="col-lg-6">
        <label class="required-field">Confirm Account #:</label>
        <input matInput placeholder="" type="text" formControlName="confirmAccNumber" autocomplete="off" maxlength="30">
        <div *ngIf="accountHolderForm['confirmAccNumber']?.touched && accountHolderForm['confirmAccNumber'].errors">
          <mat-error class="error-cus" *ngIf="accountHolderForm['confirmAccNumber']?.errors?.required ">
            This field is required.
          </mat-error>
          <mat-error class="error-cus" *ngIf="accountHolderForm['confirmAccNumber']?.errors?.pattern && !accountHolderForm['confirmAccNumber']?.errors?.required">
            This field is invalid.
          </mat-error>

        </div>
        <div class="">
          <mat-error class="error-cus" *ngIf="!confirmAccount()  && !accountHolderForm['confirmAccNumber']?.errors?.pattern">
            Confirm Account # and Account # does not match.
          </mat-error>
        </div>
      </div>

      <div class="col-lg-6">
        <label>SubAccount #:</label>
        <input matInput placeholder="" type="text" formControlName="subAccountNumber" minlength="4" maxlength="30">
        <div *ngIf="accountHolderForm['subAccountNumber']?.touched">
          <mat-error class="error-cus" *ngIf="accountHolderForm['subAccountNumber']?.errors?.pattern">
            This field is invalid.
          </mat-error>
          <mat-error class="error-cus" *ngIf="!accountHolderForm['accountNumber']?.errors?.pattern && accountHolderForm['accountNumber']?.errors?.minlength">
            Minimum length is 4.
          </mat-error>
          <mat-error class="error-cus" *ngIf="!accountHolderForm['accountNumber']?.errors?.pattern && accountHolderForm['accountNumber']?.errors?.maxlength">
            Maximum length is 30.
          </mat-error>
        </div>
      </div>



        <div class="col-lg-6">
          <label>Phone:</label>
          <input matInput placeholder="" (keyup)="chkPhoneChk($event)" type="tel" mask='(000) 000-0000' formControlName="phone">

          <div *ngIf="accountHolderForm['phone']?.touched">
            <mat-error class="error-cus" *ngIf="accountHolderForm['phone']?.dirty && accountHolderForm['phone']?.errors?.minlength">
              Phone must be of 10 digits.
            </mat-error>
          </div>
        </div>
        <div class="col-lg-6">
          <label>City:</label>
          <input matInput placeholder="" type="text" formControlName="city">
          <div *ngIf="accountHolderForm['city']?.touched">
            <mat-error class="error-cus" *ngIf="accountHolderForm['city']?.errors?.pattern">
              Please enter a valid city name.
            </mat-error>
            <mat-error class="error-cus" *ngIf="!accountHolderForm['city']?.errors?.pattern && accountHolderForm['city']?.errors?.minlength">
              City must be at least 2 characters long.
            </mat-error>
          </div>
        </div>

        <div class="col-lg-6">
          <label>Zip:</label>
          <input matInput placeholder="" type="text" formControlName="zipCode" minlength="4" maxlength="5">
          <div *ngIf="accountHolderForm['zipCode']?.touched">
            <mat-error class="error-cus" *ngIf="accountHolderForm['zipCode']?.errors?.validZip && !accountHolderForm['zipCode']?.errors?.minlength">
              This field is invalid.
            </mat-error>
            <mat-error class="error-cus" *ngIf="accountHolderForm['zipCode']?.errors?.minlength">
              {{validMessage.Message.zipFormat}}
            </mat-error>
          </div>
        </div>
        <div class="col-lg-6">
          <label>State:</label>
          <div class="ngx-select-container ngx-custom-select">
            <ngx-select autoClearSearch="true"
                        optGroupLabelField="stateCode"
                        [allowClear]="true"
                        formControlName="state"
                        [items]="stateList$ | async"
                        optionValueField="stateCode"
                        optionTextField="stateName"
                        placeholder="Select..."
                        [searchCallback]="this.searchState">
            </ngx-select>
          </div>
        </div>



      </div>


    <div class="row">
      <div class="col-lg-12">
        <h4 class="mb-0">Bank Information</h4>
      </div>
    </div>

    <div class="row" formGroupName="bankInfo">
      <div class="col-lg-6">
        <label class="required-field">Bank Name:</label>
        <input matInput placeholder="" type="text" formControlName="bankName">
        <div *ngIf="bankInformation['bankName']?.touched">
          <mat-error class="error-cus" *ngIf="bankInformation['bankName']?.errors?.required">
            This field is required.
          </mat-error>
        </div>
      </div>

      <div class="col-lg-6">
        <label class="required-field">ABA/Routing:</label>
        <input matInput placeholder="" type="text" formControlName="abaRouting" maxlength="9">
        <div *ngIf="bankInformation['abaRouting']?.touched">
          <mat-error *ngIf="bankInformation['abaRouting']?.errors?.required" class="error-cus">
            This field is required.
          </mat-error>
          <mat-error class="error-cus" *ngIf="bankInformation['abaRouting']?.errors?.pattern">
            This field is invalid.
          </mat-error>
          <mat-error class="error-cus" *ngIf="!bankInformation['abaRouting']?.errors?.pattern && bankInformation['abaRouting']?.errors?.minlength">
            ABA/Routing must be of 9 digits.
          </mat-error>
        </div>
      </div>



      <div class="col-lg-6">
        <label>Intermediary Bank Name:</label>
        <input matInput placeholder="" type="text" formControlName="intermediaryBankName">
      </div>

      <div class="col-lg-6">
        <label>Account #:</label>
        <input matInput placeholder="" type="text" formControlName="accountNumber" minlength="4" maxlength="30">
        <div *ngIf="bankInformation['accountNumber']?.touched">
          <mat-error class="error-cus" *ngIf="bankInformation['accountNumber']?.errors?.pattern">
            This field is invalid.
          </mat-error>
          <mat-error class="error-cus" *ngIf="!bankInformation['accountNumber']?.errors?.pattern && bankInformation['accountNumber']?.errors?.minlength">
            Minimum length is 4.
          </mat-error>
          <mat-error class="error-cus" *ngIf="!bankInformation['accountNumber']?.errors?.pattern && bankInformation['accountNumber']?.errors?.maxlength">
            Maximum length is 30.
          </mat-error>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-12 text-center">
        <div>
          <button mat-flat-button color="primary" class="vertical-center">
            Add
          </button>
        </div>
      </div>
    </div>

  </mat-dialog-content>
</form>
<button mat-button mat-dialog-close class="popup-close-btn"><i class="material-icons">close</i></button>
