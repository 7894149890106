import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INgxSelectOption } from 'ngx-select-ex';
import { Observable, of } from 'rxjs';
import { Validations } from '../../../constants/validations';
import { UsersService } from '../../../modules/users/users.service';
import { FormService } from '../../../services/forms/form.service';
import { Helper } from '../../classes/helpers.class';
import { StateInterface } from '../../interface/tab-interface/state.interface';

@Component({
  selector: 'app-wire-account',
  templateUrl: './wire-account.component.html',
  styleUrls: ['./wire-account.component.css']
})
export class WireAccountComponent implements OnInit {

  stateList$: Observable<StateInterface>;
  helper: Helper = new Helper();
  addWireAccountForm: FormGroup;
  submited = false;
  validMessage = Validations;
  constructor(public dialogs: MatDialog,
    public fs: FormService,
    public userApi: UsersService,
    private dialogRef: MatDialogRef<WireAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)  {
    this.addWireAccountForm = fs.newClosingAgentForm();
    this.addWireAccountForm.controls.closingAgentInfo.disable();
  }

  ngOnInit(): void {
    this.getStateList();
  }


  formCheck() {
    let isFormValid = false;
    if (this.addWireAccountForm.valid) {
      isFormValid = true;
    }

    if (!this.addWireAccountForm.valid) {
      for (let i in this.addWireAccountForm.controls.accountHolderInfo['controls']) {
        this.addWireAccountForm.controls.accountHolderInfo['controls'][i].markAsTouched();
      }

      for (let i in this.addWireAccountForm.controls.bankInfo['controls']) {
        this.addWireAccountForm.controls.bankInfo['controls'][i].markAsTouched();
      }

      isFormValid = false;
    }
    return isFormValid;
  }

  getStateList() {
    this.userApi.getState().subscribe(res => {
      if (res && res['status'] == "Success") {
        this.stateList$ = of(res['value']);
      }
    }, error => {
      this.helper.errorHandler(error);
    })
  }

  addWireAccount() {
    this.submited = true;

    let isValid = this.formCheck();

    if (!isValid) { return; }

    const addObj = this.helper.formData(this.addWireAccountForm);
    this.close(addObj);
  }

  public searchState(searchTxt: string, item: INgxSelectOption): boolean {
    let result = item.data.stateName.toLocaleLowerCase().startsWith(searchTxt.toLocaleLowerCase());
    return result;
  }

  get closingAgentForm() {
    return this.addWireAccountForm.controls.closingAgentInfo['controls'];
  }
  get accountHolderForm() {
    return this.addWireAccountForm.controls.accountHolderInfo['controls'];
  }
  get bankInformation() {
    return this.addWireAccountForm.controls.bankInfo['controls'];
  }
  chkPhone(event) {
    let realval = this.addWireAccountForm.controls.closingAgentInfo.get('phone').value;
    if (realval == "") {
      event.target.value = "";
    }
  }
  confirmAccount() {
    return ((this.accountHolderInfo.accountNumber == this.accountHolderInfo.confirmAccNumber) ||
      (this.accountHolderInfo.accountNumber == '' || this.accountHolderInfo.confirmAccNumber == '')
    ) ? true : false;
  }
  get accountHolderInfo() {
    return (this.addWireAccountForm.get('accountHolderInfo').hasOwnProperty('value') ?
      this.addWireAccountForm.get('accountHolderInfo')['value'] : null)
  }
  chkPhoneChk(event) {
    let realval = this.addWireAccountForm.controls.accountHolderInfo.get('phone').value;
    if (realval == "") {
      event.target.value = "";
    }
  }
  close(obj) {
    this.dialogRef.close(obj);
  }

}
