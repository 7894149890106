import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { MsalService } from 'src/app/services/msal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utility } from 'src/app/async/utility';
import { SharedService } from 'src/app/services/shared.service';
import { Validations } from 'src/app/constants/validations';
import { ToastrService } from 'ngx-toastr';
import { ResetPassComponent } from '../reset-pass/reset-pass.component';
import { filter } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { CookieService } from "ngx-cookie-service";
import { USER } from 'src/app/constants/const';
import jwt_decode from "jwt-decode";
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.css']
})
export class UserTypeComponent implements OnInit {
  public errorMessage;
  public isFailed = false;
  public valueObj;
  public userId;
  constructor(
    private loader: NgxUiLoaderService,
    private msalService: MsalService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    public utilService: Utility,
    public sharedApi: SharedService,
    public dialogs: MatDialog) {
  }

  ngOnInit() {
    // this.msalService.getAccessData();
    var cookieResponce = this.cookieService.get(USER);
    if(!cookieResponce || cookieResponce == ''){
      // window.location.href = "/login";
      if(window.location.pathname != '/login'){
        this.msalService.logout();
        window.location.href = '/login';
      }
      return;
    }
    const decodedUser: any = jwt_decode(cookieResponce);
    // setTimeout(() => {
    this.utilService.isLoad(true);
    if (decodedUser && decodedUser.user_id) {
      this.getUserDetails();
    }
    else {
      // window.location.href = "/login";

      if(window.location.pathname != '/login'){
        this.msalService.logout();
        window.location.href = '/login';
      }
      this.utilService.isLoad(false);
    }
    // }, 2000);
  }



  getUserDetails() {
    let self = this;
    let userDetails = self.msalService.getUser();

    var cookieResponce = this.cookieService.get(USER);
    if (!cookieResponce || cookieResponce == '') {
      // window.location.href = "/login";

      if(window.location.pathname != '/login'){
        this.msalService.logout();
        window.location.href = '/login';
      }
      return;
    }
    const decodedUser: any = jwt_decode(cookieResponce);
    let reqObj = {
    }
    if (decodedUser && decodedUser.user_id) {
      reqObj['userKey'] = decodedUser.user_id;
    }
    // if (userDetails && userDetails.hasOwnProperty("idToken")) {
    //   reqObj['userKey'] = userDetails["idToken"]["oid"];
    // }

    self.sharedApi.getUserDetails(reqObj).subscribe(value => {
      if (value) {
        self.afterLoginData(value, userDetails);
      }

    }, error => {

      self.loader.start();
      setTimeout(() => {
        self.sharedApi.getUserDetails(reqObj).subscribe(value => {
          if (value) {
            self.afterLoginData(value, userDetails);
          }

        }, error => {
          self.loader.stop();
          self.errorMessage = "This User not allocated to Guardian. Please contact with admin.";
          self.toastr.warning(self.errorMessage);
          setTimeout(() => {
            self.router.navigate(['/login']);
          }, 200);

        })
      }, 5000)

    })
  }


  //afterLoginData(value, userDetails) {
  //  let self = this;
  //  if (value && (value['status'] == 'Success') || value['status'] == 'success') {
  //    // Upload
  //    self.isFailed = false;
  //    self.errorMessage = "";
  //    setTimeout(() => {
  //      localStorage.setItem('userKey', value['value']['id']);
  //      localStorage.setItem('userKey_b2c', userDetails["idToken"]["oid"]);
  //      localStorage.setItem('userDetailsLocal', JSON.stringify(value['value']));
  //    }, 0)
  //    this.valueObj = value;
  //    self.roleManage(value);

  //    if (value && value['value'] && value['value']['isPassReset'] || value['value']['isPassReset'] != 0) {
  //      self.loader.stop();
  //      self.openDialog();
  //    } else {
  //      self.loader.stop();
  //      self.isFailed = false;
  //      self.errorMessage = "";
  //      self.roleManage(value)
  //    }


  //  } else {
  //    localStorage.clear();
  //    self.msalService.logout();
  //    self.isFailed = true;
  //    self.toastr.warning('Your are not authorized user to access this portal.');
  //    setTimeout(() => {
  //      self.router.navigate(['/login']);
  //    }, 500)

  //  }
  //}

  afterLoginData(value, userDetails) {
    let self = this;
    if (value && (value['status'] == 'Success') || value['status'] == 'success') {

      // Check User Associted with Any Profile
      // Upload
      if (value && value.hasOwnProperty("message") && value['message'] == "User Profile does not have access to Guardian User Portal") {
        this.toastr.warning(value['message']);
        // localStorage.clear();
        // self.msalService.logout();
        self.isFailed = true;

        setTimeout(function () {
          // window.location.href = '/login';

          if(window.location.pathname != '/login'){
            self.msalService.logout();
            window.location.href = '/login';
          }
        }, 1500);
        // self.router.navigate(['/login']);
        return;
      }
      localStorage.setItem('userKey', userDetails['UserIdGrd']);
      localStorage.setItem('userKey_b2c', userDetails["UserIdB2C"]);
      var currentUser = this.loginService.getLoggedInUser()
      var responce = value['value'];
      if (currentUser.role)
        responce.roleName
      localStorage.setItem('userDetailsLocal', JSON.stringify(value['value']));
      self.isFailed = false;
      //self.errorMessage = "";
      //self.roleManage(value);
      setTimeout(() => {
        localStorage.setItem('userKey', userDetails['UserIdGrd']);
        localStorage.setItem('userKey_b2c', userDetails["UserIdB2C"]);
        localStorage.setItem('userDetailsLocal', JSON.stringify(value['value']));
      }, 0)
      this.valueObj = value;
      if (value && value['value'] && value['value']['isPassReset'] || value['value']['isPassReset'] != 0) {
        self.loader.stop();
        self.openDialog();
      } else {
        self.loader.stop();
        self.isFailed = false;
        self.errorMessage = "";
        self.roleManage(value)
      }
    } else {
      self.loader.stop();
      self.errorMessage = "This User not allocated to Guardian. Please contact with admin.";
      self.toastr.warning(self.errorMessage);
      // localStorage.clear();
      // self.msalService.logout();
      self.isFailed = true;
      // self.router.navigate(['/login']);

      setTimeout(function () {
        // window.location.href = '/login';

        if(window.location.pathname != '/login'){
          self.msalService.logout();
          window.location.href = '/login';
        }
      }, 1500);
    }
  }


  openDialog() {
    let self = this
    let dialogRef = self.dialogs.open(ResetPassComponent, {
      disableClose: true,
      width: '820px'
    });

    // After added and valided form then is reutrn called it.
    dialogRef.afterClosed().pipe(
      filter(res => res)
    ).subscribe(res => {
      if (res) {
        if (res && res['status'] == true) {
          self.roleManage(this.valueObj)
        }
      }
    });
  }


  roleManage(response) {
    let self = this;
    let unAuthorisedMessage = "Your are not authorized user to access this portal.";
    if (response.hasOwnProperty("value")) {
      // Redirection
      // Lender
      localStorage.setItem('userKey', response['value']['id']);
      //
      if (response['value']['roleName'] == 'FSAdmin' || response['value']['roleName'] == 'FS Admin' || response['value']['roleName'] == 'Client Admin' || response['value']['roleName'] == 'Client Admin Manager') {
        localStorage.setItem('userType', "1");
        //self.router.navigate(['/users/list']);
        self.router.navigate(['/client/group-list']);

        if (response['value']['roleName'] == 'Client Admin' || response['value']['roleName'] == 'Client Admin Manager') {
          self.router.navigate(['/client/list']);
        }


      } else {
        self.toastr.warning(unAuthorisedMessage);
        //this.utilService.setLogout();
        // this.msalService.logout();
        setTimeout(() => {
          // window.location.href = "/login";

          if(window.location.pathname != '/login'){
            this.msalService.logout();
            window.location.href = '/login';
          }
        }, 1000)
      }
    }
  }
  getUsers() {
    localStorage.setItem('currentUser', JSON.stringify(this.msalService.getUser()))
    return this.msalService.getUser();
  }

  isLoggedIn() {
    return this.msalService.isLoggedIn();
  }

  async errorHandler(err) {
    console.error(err)
  }


  async success(res = [], type = null) {

  }

  login() {
    this.router.navigate(['/login']);
    //this.utilApi.setLogout();
    // this.msalService.logout();
  }


}
